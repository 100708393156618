import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import useModal from '../hooks/useModal';

const TutorScheduler = ({ profesorId }) => {
    const dayNames = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];
    const timeSlots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();

    
    const [freeSlots, setFreeSlots] = useState([]);

    useEffect(() => {
        const fetchPodaci = async () => {
            try {
                const response = await axios.get(`/api/planer/termini/${profesorId}`);
                setFreeSlots(response.data); // Load initial free slots
            } catch (err) {
                console.error("Greska pri prikazu termina!", err);
            }
        };

        fetchPodaci();
    }, [profesorId]);

   
    const isSlotFree = (day, timeSlot) => {
        return freeSlots.some(slot => slot.dan === day && slot.sat === timeSlot);
    };

    
    const handleSlotClick = (day, timeSlot) => {
        setFreeSlots(prevFreeSlots => {
            const slotIndex = prevFreeSlots.findIndex(slot => slot.dan === day && slot.sat === timeSlot);

            if (slotIndex !== -1) {
                // If the slot is found in freeSlots, remove it (toggle off)
                return prevFreeSlots.filter(slot => slot.dan !== day || slot.sat !== timeSlot);
            } else {
                // If the slot is not in freeSlots, add it (toggle on)
                return [...prevFreeSlots, { dan: day, sat: timeSlot }];
            }
        });
        // console.log(freeSlots)
    };

    const handleSaveClick = async () => {
        try {
            await axios.post(`/api/planer/sacuvaj_termine`, {
                profesorId,
                selectedSlots: freeSlots // Send the updated freeSlots
            });
            // alert("Termini su uspješno sačuvani!");
            handleFormSuccess("Termini su uspješno sačuvani!");
        } catch (err) {
            console.error("Error saving slots:", err);
            handleFormFailure("Greška prilikom čuvanja termina!");
            // alert("Greška prilikom čuvanja termina.");
        }
    };

    return (
        <>
            {modalVisible && <Modal {...modalContent} onClose={handleCloseModal}/>} 
        <div className='tutor-box settings-container tutor-calendar'>
            <h2>Izaberite slobodne termine, a onda klik na sačuvaj!</h2>
            <table className="scheduler-table">
                <thead>
                    <tr>
                        <th></th>
                        {dayNames.map(day => (
                            <th key={day}>{day}</th>
                        ))}
                    </tr>
                </thead>
                
                <tbody>
                    {timeSlots.map(slot => (
                        <tr key={slot}>
                            <td>{slot}</td>
                            {dayNames.map(day => {
                                const isFree = isSlotFree(day, slot);

                                return (
                                    <td
                                        key={`${day}-${slot}`}
                                        className={`time-slot-tutor ${isFree ? 'time-slot-tutor-free' : ''}`}
                                        onClick={() => handleSlotClick(day, slot)}
                                    >
                                        {isFree ? 'X' : ''}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            <button className='btn-tutor' style={{position:'static', marginTop:'35px'}} onClick={handleSaveClick}>Sačuvaj</button>
        </div>
        </>
    );
};

export default TutorScheduler;
