// src/pages/Success.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <section id="work-section" className="section-bg d-flex section-bg-w">
            <div className="container">
                <div className="row justify-content-center">
                <div className="tutor-box" style={{margin:'0', minHeight:'auto'}}>
                    <div className="section-header"><p style={{fontSize:'48px'}}>Uspješno ste <span>registrovani!</span></p></div>
                    <div className="text-center"><button className="btn-prim" onClick={handleLoginRedirect}>PRIJAVA</button></div>
                </div>
                </div>
            </div>
        </section>
    );
};


export default Success;