import { useLocation } from 'react-router-dom';

const useScrollToSection = (sectionId) => {
  const location = useLocation();

  const handleScrollToSection = (e) => {
    if (location.pathname === '/' || (location.pathname === '/postani-profesor' && sectionId === 'register-tutor')) { // Ova linija moze da se prilagodi za razlicite rute
      e.preventDefault();
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return handleScrollToSection;
};

export default useScrollToSection;
