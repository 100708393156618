import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { useContext } from 'react';
import NotFoundPage from './NotFoundPage';
import useTutorReviews from '../hooks/useTutorReviews';
import Modal  from '../components/Modal';
import useModal from '../hooks/useModal';
import Scheduler from '../components/Scheduler';

import axios from 'axios';

const Tutor = () => {  
    const { getReviewText, getStars } = useTutorReviews();
    const {currentUser} = useContext(AuthContext);
    const { modalVisible, modalContent, handleFormFailure, handleCloseModal } = useModal();
    //***provjera da li je link validan i ucitavanje podataka***///
    const { 'ime-prezime-id': imePrezimeId } = useParams(); // Mora da se slaže s nazivom rute
    const parts = imePrezimeId.split('-');
    const id_profesora = parts[parts.length - 1]; 
    const [profesor, setProfesor] = useState(null);
    const [showScheduler, setShowScheduler] = useState(false);
    
    useEffect(() => {
        const fetchProfesor = async () => {
            try {
                const response = await axios.get(`/api/tutor/profesori/${id_profesora}`);
                setProfesor(response.data);
            } catch (err) {
                console.error("Greska pri dobijanju podataka o profesoru", err);
            }
        };
        fetchProfesor();
    }, [id_profesora]);
    
    const isValid = profesor && parts.length === 3 && !isNaN(id_profesora) && parts[0].toLowerCase() === profesor.ime.split(' ')[0].toLowerCase() && parts[1].toLowerCase() === profesor.ime.split(' ')[1].toLowerCase();
    
    if (!isValid) {
        return <NotFoundPage />;
    }
    //***provjera da li je link validan i ucitavanje podataka***///


    //***dugme za prikaz kalendara****/

    const handleAppointmentButton = (e) => {
        e.preventDefault(); 
        
        if (!currentUser) {
            handleFormFailure('Morate biti prijavljeni da biste zakazali čas.');
            return;
        }
        if(currentUser.id_tipa === 2){
            handleFormFailure("Ulogovani ste kao profesor! Časove zakazuju samo studenti.");
            return;
        }
        setShowScheduler(true);
        
    };
    
    return (
        <>
        {modalVisible && <Modal {...modalContent} onClose={handleCloseModal} />}
        {currentUser && showScheduler && <Scheduler onClose={() => setShowScheduler(false)} profesorId={profesor.id_korisnika} />}
        <section id="work-section" className="section-bg d-flex section-bg-w">
            <div className="container">
            
                    {/* <div className="section-header">
                        <h2>profesor</h2>
                        <p>Neki <span>tekst</span></p>
                    </div> */}

                    <div className="row justify-content-center tutor-page">
                   
                        <div className="tutor-box d-flex">
                            <div className='rec d-flex'>
                                    <div className='t-img-s'>
                                        <img src={`${profesor.img}`} alt='' className="rounded-circle"/>
                                        {getStars(profesor.prosjecnaOcjena)}
                                    </div>
                                    <div className='tx-l'>
                                        <h3>{profesor.ime}</h3>
                                        <div className='d-flex align-items-baseline'>
                                            <h4>Rejting:</h4>
                                            <p style={{ marginLeft: '10px' }}>{profesor.prosjecnaOcjena ? parseFloat(profesor.prosjecnaOcjena).toFixed(2) : "0"}</p>
                                        </div>
                                        {profesor.verifikovan === 1 &&  <i className="bi bi-patch-check-fill"></i>}
                                    </div>
                                     <button className='btn-tutor' onClick={handleAppointmentButton}>ZAKAŽI ČAS</button>
                            </div>
                            <div className='bio'>
                    
                                            <h4>Cijena:</h4>
                                            {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 1) && (
                                                <p>
                                                   <span style={{fontWeight:'600'}}>Osnovna škola:</span> {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 1).nivo_cijena}€ 45min
                                                </p>
                                            )}

                                            {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 2) && (
                                                <p>
                                                   <span style={{fontWeight:'600'}}>Srednja škola:</span> {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 2).nivo_cijena}€ 45min
                                                </p>
                                            )}
                                            {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 3) && (
                                                <p>
                                                    <span style={{fontWeight:'600'}}>Fakultet:</span> {profesor.cijenaPoNivoima.find(nivo => nivo.id_nivoa === 3).nivo_cijena}€ 45min
                                                </p>
                                            )}

                                
                            </div>
                            <div className='info'>
                    
                                <h4>Predmeti:</h4>
                                <div className='d-flex w-flex'>
                                {profesor.predmeti.map((predmet, index) => (
                                    <span key={index} className='upper'>{predmet}</span>
                                ))}  
                                </div>
                                
                            </div>
                            <div className='bio'>
                                <h4>Biografija</h4>
                                <p>{profesor.biografija}</p>
                            </div>
                            
                            <div className='bio recensions'>
                                <h4>Recenzije</h4>
                                <p>{getReviewText(profesor.brojRecenzija)} učenika</p>
                                {profesor.recenzije.map((recenzija, index) => (
                                   <div key={index} className='d-flex gap-4 recension'>
                                        {recenzija.img ? (
                                            <img src={recenzija.img} alt="" className="rounded-circle" />
                                            ) : (
                                            <img src="/assets/images/student-logo.png" alt="default" className="rounded-circle" />
                                        )}
                                        <div className='rec-info'>
                                            <div className='d-flex rec-row'>
                                                <p><span>{recenzija.ime} {recenzija.prezime}</span></p>
                                                <p>{getStars(recenzija.ocjena)}</p>
                                            </div>
                                            <p>{recenzija.tekst}</p>
                                        </div>
                                    </div>
                                ))} 
                            </div>
                               
                        </div>
                        
                    </div>
            </div>
        </section>
        </>
    );
};

export default Tutor;
