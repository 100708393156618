import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TutorsList from '../components/TutorsList';
import axios from 'axios';


const TutorsForSubjectPage = () => {
    const { link } = useParams();
    const [profesori, setProfesori] = useState([]);
    const [tip, setTip] = useState("all");
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("recommended");

    useEffect(() => {
        // Resetovanje filtera pri promjeni linka
        setSearchTerm("");
        setSearch("");    
        setSort("recommended"); 
        setTip("all");
    }, [link]);
    
    useEffect(() => {
        //ucitavanje profesora pri promjeni linka,nivoa,searcha,sorta
        const fetchProfesori = async () => {
            setProfesori([]);
            try {
                const response = await axios.get(`/api/tutor/predmeti/${link}`, {
                    params: { tip, search, sort }
                });
                setProfesori(response.data);
            } catch (err) {
                console.error("Greska pri prikazu profesora!", err);
            }
        };
        fetchProfesori();
    }, [link, tip, search, sort]);
    
    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearch(searchTerm);
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    return (
        <>
        <section id="work-section" className="section-bg d-flex section-bg-w">
            <div className="container">
                
                        <div className="section-header">
                            <h2>profesori</h2>
                            <p>Svi profesori za predmet - <span>{link.split('-').join(' ')}</span></p>
                        </div>

                        <div className="row justify-content-center tutor-filters">
                                <div className="col-lg-6 d-flex flex-column  align-items-start text-lg-start flt1">
                                    <p>Pretraži po imenu profesora</p>
                                    <form id="form_submit" className="search-wrapper" onSubmit={handleSearchChange}>
                                        <div className="group">
                                            <button type="submit" name="submit_search"><i className="bi bi-search"></i></button>
                                            <input id="search-input" type="search" name="search" placeholder="Ime ili prezime profesora" className="holderChange" value={searchTerm} onChange={handleSearchTermChange}/>
                                        </div>
                                    </form>
                                    <div className="section-sort mt-3">
                                            <label htmlFor="sort">Sortiraj po:</label>
                                            <select name="sort" id="sort" className="ms-2" value={sort} onChange={handleSortChange}>
                                                <option value="recommended">relevantnosti</option>
                                                <option value="name">imenu</option>
                                                <option value="priceAscOrder">najniža cijena prvo</option>
                                                <option value="priceDescOrder">najveća cijena prvo</option>
                                            </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex flex-column  align-items-start text-lg-start flt2">
                                    <p>Izaberi nivo</p>
                                    <div className="choose-lvl d-flex  w-flex">
                                        <div className="form-group d-flex">
                                            <input type="radio" id="allLevels" name="chooseLvl" value="all" checked={tip === "all"} onChange={() => setTip("all")}/>
                                            <label htmlFor="allLevels">Svi nivoi</label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="radio" id="primaryScInp" name="chooseLvl" value="1" checked={tip === "1"} onChange={() => setTip("1")}/>
                                            <label htmlFor="primaryScInp">Osnovna škola</label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="radio" id="highScInp" name="chooseLvl" value="2" checked={tip === "2"} onChange={() => setTip("2")}/>
                                            <label htmlFor="highScInp">Srednja škola</label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <input type="radio" id="uniInp" name="chooseLvl" value="3" checked={tip === "3"} onChange={() => setTip("3")}/>
                                            <label htmlFor="uniInp">Fakultet</label>
                                        </div>
                                       
                                    </div>
                                </div>
                                <p style={{marginTop: '20px'}}>Broj dostupnih profesora: {profesori.length}</p>
                        </div>
                        
                        {profesori.length > 0 ? (
                            <>
                                <TutorsList profesori={profesori} />
                            </>
                        ) : (
                            <div className="section-header">
                                <p>Nema dostupnih profesora!</p>
                            </div>
                        )}
            </div>
        </section>
        </>
    );
}

export default TutorsForSubjectPage;
