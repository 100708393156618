import React from 'react';
import useForm from '../hooks/useForm';

const RegistrationForm = ({ formType, onToggleForm, onSuccess, onFailure }) => {
    const { formData, handleInputChange, handleSubmit } = useForm(formType, onSuccess, onFailure);

    return (
        <>
            <div className="section-header">
                <h2>{formType === 'login' ? 'Prijava' : 'Registracija'}</h2>
                <p>{formType === 'login' ? 'Krećemo!' : 'Spremni?'} <span>{formType === 'login' ? 'Uloguj se!' : 'Napravite nalog!'}</span></p>
            </div>
            <div className="row">
                <form onSubmit={handleSubmit} className="contact-email-form p-3 p-md-4 col-lg-6 register-tutor" style={{ margin: '0 auto' }}>
                    {(formType === 'register-teacher' || formType === 'register-student') && (
                        <>
                            <div className="form-group">
                                <label htmlFor="name">Ime i Prezime</label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control" 
                                    id="name" 
                                    placeholder="Ime i Prezime" 
                                    required 
                                    autoComplete="on"
                                    value={formData.name} 
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="dob">Datum Rođenja</label>
                                <input 
                                    type="date" 
                                    name="dob" 
                                    className="form-control" 
                                    id="dob" 
                                    required
                                    value={formData.dob} 
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">Broj Telefona</label>
                                <input 
                                    type="tel" 
                                    name="phone" 
                                    className="form-control" 
                                    id="phone" 
                                    placeholder="Broj Telefona" 
                                    autoComplete="on"
                                    required
                                    value={formData.phone} 
                                    onChange={handleInputChange}
                                />
                            </div>
                        </>
                    )}

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            name="email" 
                            id="email" 
                            placeholder="Email" 
                            required 
                            autoComplete="on"
                            value={formData.email} 
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Lozinka</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            name="password" 
                            id="password" 
                            placeholder="Lozinka" 
                            required 
                            autoComplete="on"
                            value={formData.password} 
                            onChange={handleInputChange}
                        />
                    </div>

                    {formType === 'register-teacher' && (
                        <div className="form-group">
                            <label htmlFor="cv">CV</label>
                            <textarea
                                name="cv" 
                                className="form-control" 
                                id="cv" 
                                placeholder="Recite nam više o sebi" 
                                required
                                value={formData.cv} 
                                onChange={handleInputChange}
                            />
                        </div>
                    )}

                    {(formType === 'register-teacher' || formType === 'register-student') && (
                        <div className="d-flex align-items-center">
                            <input type="checkbox" name="policy" id="policy" required />
                            <label htmlFor="policy" style={{ margin: '0 0 0 7px' }}>
                                Prihvatam <a href="/uslovi-koriscenja">uslove korišćenja</a> i <a href="/politika-privatnosti">politiku privatnosti</a>.
                            </label>
                        </div>
                    )}

                    <div className="text-center">
                        <button type="submit">
                            {formType === 'login' ? 'ULOGUJ SE' : 'NAPRAVI NALOG'}
                        </button>
                    </div>
                </form>
                
                {(formType === 'login' || formType === 'register-student') && (
                    <div className="text-center" style={{ fontWeight: 600 }}>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            <span>
                                {formType === 'login' ? 'Nemate nalog?' : 'Već imate nalog?'}
                            </span>
                            <a href="#proba" onClick={onToggleForm} style={{ marginLeft: '7px' }}>
                                {formType === 'login' ? 'Registruj se' : 'Uloguj se'}
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                        <a href="/forget">Zaboravili ste lozinku?</a>
                    </div>
                )}
            </div>
        </>
    );
};

export default RegistrationForm;