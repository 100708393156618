import { useContext } from "react";
import { AuthContext } from "./authContext";
import { Navigate } from "react-router-dom";

const ProtectiveRoutesAdmin = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // Proveri da li je korisnik admin (id_tipa za admina je, na primer, 1)
  if (!currentUser || currentUser.id_tipa !== 1) {
    return <Navigate to="/" />; // Preusmeri na početnu stranicu ako nije admin
  }

  return children;
};

export default ProtectiveRoutesAdmin;
