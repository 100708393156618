import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useMobileNav = () => {
  const location = useLocation();

  useEffect(() => {
    const handleShowHeader = () => {
      document.querySelector('body').classList.add('mobile-nav-active');
      document.querySelector('.mobile-nav-hide').classList.remove('d-none');
      document.querySelector('.mobile-nav-show').classList.add('d-none');
    };

    const handleHideHeader = () => {
      document.querySelector('body').classList.remove('mobile-nav-active');
      document.querySelector('.mobile-nav-hide').classList.add('d-none');
      document.querySelector('.mobile-nav-show').classList.remove('d-none');
    };

    const handleDropdownClick = (event) => {
      if (document.querySelector('.mobile-nav-active')) {
        event.preventDefault();
        event.currentTarget.classList.toggle('active');
        event.currentTarget.nextElementSibling.classList.toggle('dropdown-active');

        const dropDownIndicator = event.currentTarget.querySelector('.dropdown-indicator');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    };

    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');
    const navbarLinks = document.querySelectorAll('#navbar .hide-header');
    const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

    if (mobileNavShow) {
      mobileNavShow.addEventListener('click', handleShowHeader);
    }
    if (mobileNavHide) {
      mobileNavHide.addEventListener('click', handleHideHeader);
    }

    navbarLinks.forEach(link => {
      link.addEventListener('click', handleHideHeader);
    });

    navDropdowns.forEach(el => {
      el.addEventListener('click', handleDropdownClick);
    });

    // sakrij header kad se promjeni ruta
    handleHideHeader();

    return () => {
      if (mobileNavShow) {
        mobileNavShow.removeEventListener('click', handleShowHeader);
      }
      if (mobileNavHide) {
        mobileNavHide.removeEventListener('click', handleHideHeader);
      }
      navbarLinks.forEach(link => {
        link.removeEventListener('click', handleHideHeader);
      });
      navDropdowns.forEach(el => {
        el.removeEventListener('click', handleDropdownClick);
      });
    };
  }, [location.pathname]);
};

export default useMobileNav;
