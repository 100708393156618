import React from 'react';
import NavLoggedIn from '../components/NavLoggedIn';
import {AuthContext} from "../context/authContext.js";
import { useContext } from 'react';
import ReservationList from '../components/ReservationList.js';

const LoggedInPage = () => {
    const {currentUser}= useContext(AuthContext);
    return (
            <section id="work-section" className="hero d-flex section-bg-w">
                <div className="container">
                    <div className="row justify-content-between gy-5">
                        <div className="menu-side col-lg-4 order-2 order-lg-1 d-flex flex-column align-items-center text-center text-lg-start">
                            <NavLoggedIn/>
                        </div>
                        <div className="col-lg-8 order-1 order-lg-2 d-flex flex-column justify-content-center text-center">
                            <h2 data-aos="fade-up" className="aos-init aos-animate">Napomena !</h2>
                            <p data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate" >Naglašavamo da je strogo zabranjeno dijeljenje kontaktnih informacija van ove platforme. Ukoliko posumnjamo da kršite ovo pravilo, Vaš nalog će biti ugašen. Hvala na razumijevanju.</p>

                            <ReservationList idKorisnika={currentUser.id_korisnika} tipKorisnika={currentUser.id_tipa}/>
                        </div>
                    </div>
                </div>
            </section>

    );
};

export default LoggedInPage;