import React, { useEffect, useState } from 'react';
import SubjectList from '../components/SubjectList';
import axios from 'axios';

const SubjectsListPage = () => {
    const [predmeti, setPredmeti] = useState([]);

    useEffect(() => {
        const fetchPredmeti = async () => {
            try {
                const response = await axios.get('/api/subj/predmeti');
                setPredmeti(response.data);
            } catch (err) {
                console.error("Greska pri prikazu predmeta!", err);
            }
        };

        fetchPredmeti();
    }, []);

    return (
        <>
        <section id="work-section" className="section-bg d-flex section-bg-w">
            <div className="container">
            <div className="section-header">
                <h2>predmeti</h2>
                <p>Svi <span>predmeti</span></p>
            </div>
                <SubjectList predmeti={predmeti} />
            </div>
        </section>
        </>
    );
}

export default SubjectsListPage;