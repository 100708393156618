import React, {useContext} from 'react';
import useScrollToSection from '../hooks/useScrollToSection';
import { NavLink, Navigate } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import Modal from '../components/Modal';
import useModal from '../hooks/useModal';

import { AuthContext } from "../context/authContext.js";

const BecomeTutorPage = () => {
  
    const {currentUser} = useContext(AuthContext);  
    const handleRegisterTutorClick = useScrollToSection('register-tutor');
    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();
    
    if(currentUser) return <Navigate to="/dashboard"/>


    return (
        <main id="main">
        {modalVisible && <Modal {...modalContent} onClose={handleCloseModal} />}
        
        <section id="hero" className="hero d-flex align-items-center section-bg-w">
          <div className="container">
            <div className="row justify-content-between gy-5">
              <div className="col-lg-5 order-1 order-lg-2 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                <h2 data-aos="fade-up" className="aos-init aos-animate">Poveži se lako sa<br/>novim studentima</h2>
                <p data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">Sed autem laudantium dolores. Voluptatem itaque ea consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
                <div className="d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                  <NavLink to='#register-tutor' onClick={handleRegisterTutorClick} className="hero-a-btn">Prijavite se</NavLink>
                </div>
              </div>
              <div className="col-lg-6 order-2 order-lg-1 text-center text-lg-start">
                <img src="/assets/images/learning.jpg" className="img-fluid aos-init aos-animate" alt="" data-aos="zoom-out" data-aos-delay="300"/>
              </div>
            </div>
          </div>
        </section>
        
        <section id="why-us" className="why-us section-bg">
          <div className="container" data-aos="fade-up">
    
            <div className="row gy-4">
    
              <div className="col-lg-12 d-flex align-items-center">
                <div className="row gy-4">
    
                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center tutors-help">
                      <i className="icofont-ui-clock"></i>
                      <h4 className="smaller-font">Birate kada i koliko radite</h4>
                      <p>Sami kreirajte sebi raspored i odredite dostupnost. Imate punu slobodu da sami izaberete cenu za svoje usluge.</p>
                    </div>
                  </div>
    
                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center tutors-help">
                      <i className="icofont-megaphone"></i>
                      <h4 className="smaller-font">Oglašavamo za vas</h4>
                      <p>Na vama je samo da se učlanite i popunite profil a mi ćemo se potruditi da budete viđeni što većem broju potencijalnih učenika.</p>
                    </div>
                  </div>
    
                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center tutors-help">
                      <i className="icofont-live-support"></i>
                      <h4 className="smaller-font">Podrška kad god vam treba</h4>
                      <p>Tu smo za vas! Za bilo kakva pitanja i nedoumice stojimo vam na raspolaganju</p>
                    </div>
                  </div>
    
                </div>
              </div>
    
            </div>
    
          </div>
        </section>
        
        <section id="tutors" className="tutors section-bg-w">
          <div className="container aos-init aos-animate" data-aos="fade-up">
    
            <div className="section-header">
              <h2>utisci</h2>
              <p>Šta drugi <span>kažu</span> o nama</p>
            </div>
    
            <div className="row gy-4">
    
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div className="tutor-member">
                  <div className="member-img">
                    <img src="/assets/images/tutors-1.jpg" className="img-fluid" alt="Profesor"/>
                  </div>
                  <div className="member-info">
                    <h4>Petar Perović</h4>
                    <span>DOKTOR PROGRAMIRANJA</span>
                    <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                <div className="tutor-member">
                  <div className="member-img">
                    <img src="/assets/images/tutors-2.jpg" className="img-fluid" alt="Profesor"/>
                  </div>
                  <div className="member-info">
                    <h4>Matija Radović</h4>
                    <span>PROGRAMERSKA MAGIJA</span>
                    <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
                  </div>
                </div>
              </div>
    
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div className="tutor-member">
                  <div className="member-img">
                    <img src="/assets/images/tutors-3.jpg" className="img-fluid" alt="Profesor"/>
                  </div>
                  <div className="member-info">
                    <h4>Filip Investitor</h4>
                    <span>ZNA DA NAMIRISE BIZNIS</span>
                    <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
                  </div>
                </div>
              </div>
    
            </div>
    
          </div>
        </section>
    
        <section id="register-tutor" className="contact section-bg">
          <div className="container" data-aos="fade-up">
        
    
              <RegistrationForm formType={"register-teacher"} onSuccess={handleFormSuccess} onFailure={handleFormFailure}/>
            
    
          </div>
        </section>
      </main>
    );
};

export default BecomeTutorPage;