import { createContext, useEffect, useState } from "react";
import axios from "axios";
// import {jwtDecode} from "jwt-decode"; 
// import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    // const navigate = useNavigate();

    // Function to log in the user
    const login = async (inputs) => {
        
        const res = await axios.post("/api/auth/login", inputs, {
            withCredentials: true,
        });
        setCurrentUser(res.data); // Store the user data from the response
        localStorage.setItem("user", JSON.stringify(res.data));
    };

    // Function to log out the user
    const logout = async () => {
        try {
            await axios.post("/api/auth/logout", {}, {
                withCredentials: true, // Omogući slanje kolačića sa zahtevom
            });
            setCurrentUser(null);
            localStorage.removeItem("user");
            window.location.replace("https://casovi.me/login");
        } catch (err) {
            console.error("Greška prilikom odjavljivanja:", err);
        }
    };
    

    // Function to refresh the token
    const refreshAccessToken = async () => {
        try {
          await axios.get("/api/auth/refresh/token/new", {
            withCredentials: true, // Omogući slanje kolačića sa zahtevom
          });
        } catch (err) {
          logout(); // Ako osvežavanje tokena nije uspelo, odjavi korisnika
        }
      };
      

    // Function to set a timer for refreshing the token
    const setTokenRefreshTimer = () => {
        if (currentUser) {
            // Immediately refresh the token
            refreshAccessToken();
            console.log("Timer stavljen")
            //console.log("Kolačići na klijentskoj strani:", document.cookie);

            // Set an interval to refresh the token every 15 minutes
            const refreshInterval = 14 * 60 * 1000; // 14 minutes
            const interval = setInterval(() => {
                refreshAccessToken();
            }, refreshInterval);

            // Clean up the interval on logout or user change
            return () => clearInterval(interval);
        }
    };

    // Run the token refresh timer setup whenever the user logs in or the page is reloaded
    useEffect(() => {
        const intervalCleanup = setTokenRefreshTimer(); // Set up the refresh timer
        return () => intervalCleanup && intervalCleanup(); // Clean up on component unmount or user change
    }, [currentUser]);

    // Save the current user to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{ currentUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

