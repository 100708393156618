import { useState, useEffect } from 'react';
import { addDays, subDays, format } from 'date-fns';
import axios from 'axios';

const dayNames = ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'];
const timeSlots = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];

// funkcija za formatiranje datuma ovo je za prikaz datuma izmedju < strelica >
export const formatCustomDate = (date) => {
    const day = format(date, 'd'); // broj dana
    const month = monthNames[date.getMonth()]; // mjesec iz niza
    return `${day} ${month}`;
};

// hook za logiku svega zivoga za scheduler
const useScheduler = (profesorId) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedSlotsByWeek, setSelectedSlotsByWeek] = useState({}); //mapa za cuvanje selektovanih po sedmicama
    const [predmeti, setPredmeti] = useState([]);
    const [nivoi, setNivoi] = useState([]);
    const [selectedNivo, setSelectedNivo] = useState("default");
    const [selectedPredmet, setSelectedPredmet] = useState("default");
    const [showNivoi, setShowNivoi] = useState(false);
    const [freeSlots, setFreeSlots] = useState([]);
    const [reservedSlots, setReservedSlots] = useState([]);

    const [hoveredSlot, setHoveredSlot] = useState(null);
    const [selectedSlots, setSelectedSlots] = useState([]);

    const [duration, setDuration] = useState(60); // za pracenje trajanja casa (60 ili 120 minuta)
    
    const generateDaysOfWeek = (startDay) => Array.from({ length: 7 }, (_, i) => addDays(startDay, i));// pravi niz od 7 dana a pocinje od startDay
    const daysOfWeek = generateDaysOfWeek(currentDate);
    const getWeekKey = (date) => format(date, 'yyyy-MM-dd');

    const [reload, setReload] = useState(false);

    // zabraniti da se vrati na sedmicu iza ako je prosao taj datum
    const handlePrevWeek = () => {
        const today = new Date();
        const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const prevWeek = subDays(currentDate, 7);
        if (resetTime(prevWeek) < resetTime(today)) return;
        setCurrentDate(prevWeek);
    };

    const handleNextWeek = () => {
        setCurrentDate(addDays(currentDate, 7));
    };

    // ucitavanje podataka
    useEffect(() => {
        const fetchPodaci = async () => {
            try {
                const response = await axios.get(`/api/planer/predmeti/${profesorId}`);
                setPredmeti(response.data);

                const response2 = await axios.get(`/api/planer/termini/${profesorId}`);
                setFreeSlots(response2.data);

                const startDate = format(currentDate, 'yyyy-MM-dd');
                const endDate = format(addDays(currentDate, 6), 'yyyy-MM-dd');
                const response3 = await axios.get(`/api/planer/rezervacije/${profesorId}?startDate=${startDate}&endDate=${endDate}`);
                setReservedSlots(response3.data);
            } catch (err) {
                console.error("Greska pri prikazu predmeta!", err);
            }
        };

        fetchPodaci();
    }, [profesorId, currentDate, reload]);

    const isSlotFree = (day, timeSlot) => {
        return freeSlots.some(slot => slot.dan === day && slot.sat === timeSlot);
    };

    const isSlotReserved = (day, timeSlot) => {
        return reservedSlots.some(slot => slot.dan === day && slot.sat === timeSlot);
    };

    // ucitavanje nivoa
    const fetchNivoi = async (selectedPredmet) => {
        try {
            const response = await axios.get(`/api/planer/nivoi/${profesorId}/${selectedPredmet}`);
            setNivoi(response.data);
        } catch (err) {
            console.error("Greska pri prikazu nivoa!", err);
        }
    };

    // mjenjaj predmete i nivoe po primjeni predmeta
    const handlePredmetChange = (e) => {
        const predmet = e.target.value;
        setSelectedPredmet(predmet);
        if (predmet !== "default") {
            setShowNivoi(true);
            fetchNivoi(predmet);
        } else {
            setShowNivoi(false);
            setSelectedNivo("default");
        }
    };

    // promjena nivoa
    const handleNivoChange = (e) => {
        setSelectedNivo(e.target.value);
    };

    const handleMouseEnter = (day, timeSlot) => {
        setHoveredSlot({ day, timeSlot });
    };
    
    const handleMouseLeave = () => {
        setHoveredSlot(null);
    };
    
    const handleSlotClick = (day, timeSlot, selectedDayDate) => {
        setSelectedSlotsByWeek({}); //kad kliknem na slot prethodne kliknute brisem iz mape
        setSelectedSlots([]);
        const currentWeekKey = getWeekKey(currentDate);
    
        let newSelectedSlots = [{ day, timeSlot, date: selectedDayDate }];
    
        if (duration === 120) {
            const nextSlotIndex = timeSlots.indexOf(timeSlot) + 1;
    
            if (nextSlotIndex < timeSlots.length) {
                const nextTimeSlot = timeSlots[nextSlotIndex];
                // provjera je li naredni termin slobodan
                if (isSlotFree(day, nextTimeSlot) && !isSlotReserved(day, nextTimeSlot)) {
                    newSelectedSlots.push({ day, timeSlot: nextTimeSlot, date: selectedDayDate });
                } else {
                    // ako naredni termin nije slobodan vracam trajanje na 60 minuta
                    setDuration(60);
                }
            } else {
                setDuration(60); // ako je poslednji slot vracam trajanje na 60 minuta
            }
        }
    
        // cuvanje selektovanih polja za trenutnu sedmicu
        setSelectedSlotsByWeek((prev) => ({
            ...prev,
            [currentWeekKey]: newSelectedSlots
        }));
        //cuvanje selektovanih polja, za slanje mi trb
        setSelectedSlots(newSelectedSlots);
    };
    
    const isHovered = (day, timeSlot) => {
        if (!hoveredSlot) return false;
        if (hoveredSlot.day === day && hoveredSlot.timeSlot === timeSlot) return true;
    
        // provjera da li je termin ispod haverovan
        if (duration === 120) {
            const nextSlotIndex = timeSlots.indexOf(hoveredSlot.timeSlot) + 1;
            if (nextSlotIndex < timeSlots.length) {
                const nextTimeSlot = timeSlots[nextSlotIndex];
                if (hoveredSlot.day === day && nextTimeSlot === timeSlot) {
                    return true;
                }
            }
        }
        return false;
    };

    const isSelected = (day, timeSlot) => {
        const currentWeekKey = getWeekKey(currentDate);
        return selectedSlotsByWeek[currentWeekKey]?.some(slot => slot.day === day && slot.timeSlot === timeSlot) || false;
    };
    
    return {
        // currentDate,
        dayNames,
        daysOfWeek,
        predmeti,
        nivoi,
        selectedNivo,
        selectedPredmet,
        showNivoi,
        timeSlots,
        handlePrevWeek,
        handleNextWeek,
        handlePredmetChange,
        handleNivoChange,
        formatCustomDate,
        isSlotFree,
        isSlotReserved,
        isHovered,isSelected,handleMouseEnter,handleSlotClick,handleMouseLeave,
        duration,setDuration,
        selectedSlots,
        setReload, setSelectedNivo, setSelectedPredmet, setSelectedSlots, setShowNivoi
    };
};

export default useScheduler;
