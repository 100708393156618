import React, {useState} from 'react';
import Modal from '../components/Modal';
import RegistrationForm from '../components/RegistrationForm';
import { Navigate } from 'react-router-dom';
import useModal from '../hooks/useModal';
import { AuthContext } from '../context/authContext'
import { useContext } from 'react';

const LoginPage = () => {
    const {currentUser} = useContext(AuthContext);  
    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();
    const [currentForm, setCurrentForm] = useState('login'); // pocetno stanje je register-student
    
    const toggleForm = () => {
        setCurrentForm(currentForm === 'login' ? 'register-student' : 'login');
    };

    if(currentUser) return <Navigate to="/dashboard"/>

    return (
       <main>
        {modalVisible && <Modal {...modalContent} onClose={handleCloseModal} />}

            <section id="login-section" className="contact d-flex align-items-center section-bg">
                <div className="container" data-aos="fade-up">
                    <RegistrationForm formType={currentForm} onToggleForm={toggleForm} onSuccess={handleFormSuccess} onFailure={handleFormFailure}/>
                </div>
            </section>
      </main>
    );
};

export default LoginPage;