import React from 'react';
import { NavLink} from 'react-router-dom'; 

const NavLoggedIn = () => {

  return (
            <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                <NavLink to="/dashboard" className={({ isActive }) => isActive ? "active" : ""}><i className="bi bi-house" /><span> Časovi</span></NavLink>
                <NavLink to="/poruke" className={({ isActive }) => isActive ? "active" : ""}><i className="bi bi-chat-dots" /><span> Poruke</span></NavLink>
                <NavLink to="/podesavanja" className={({ isActive }) => isActive ? "active" : ""}><i className="bi bi-gear" /><span> Podešavanja</span></NavLink>
            </div>

  
);
};

export default NavLoggedIn;