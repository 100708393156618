import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import useMobileNav from '../hooks/useMobileNav';
import useScrollToSection from '../hooks/useScrollToSection';
import axios from 'axios';
import { AuthContext } from "../context/authContext.js";
import socket from '../components/Socket'; // Uvezi socket iz socket.js

const Header = () => {
  const [predmeti, setPredmeti] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0); // Ukupan broj svih notifikacija
  const [messageNotifications, setMessageNotifications] = useState(0); // Broj notifikacija tipa 1 (poruke)
  const [classNotifications, setClassNotifications] = useState(0); // Broj notifikacija tipa 2 (časovi)
  const { currentUser, logout } = useContext(AuthContext);

  useMobileNav();
  const handleContactClick = useScrollToSection('contact');
  // const handleContactClick = () =>{
  //   window.location.replace(
  //     "http://localhost:3000/#contact",
  //   );
  // }
  useEffect(() => {
    const fetchPredmeti = async () => {
      try {
        const response = await axios.get('/api/subj/predmeti');
        setPredmeti(response.data);
      } catch (error) {
        console.error('Greška prilikom učitavanja predmeta:', error);
      }
    };

    fetchPredmeti();

    if (currentUser) {
      // Emituj registraciju za socket samo jednom
      socket.emit('register', currentUser.id_korisnika);

      // Slušaj događaj za nove notifikacije
      socket.on('newNotification', (notification) => {
        console.log(notification.tip_notifikacije)
        if (notification.receiver_id === currentUser.id_korisnika) {
          setTotalNotifications(prev => prev + 1);
          if (notification.tip_notifikacije === 1) {
            setMessageNotifications(prev => prev + 1);
          } else if (notification.tip_notifikacije === 2) {
            setClassNotifications(prev => prev + 1);
          }
        }
      });

      // Slušaj događaj za nepročitane notifikacije pri registraciji
      socket.on('unreadNotifications', (unreadNotifications) => {
        const messageCount = unreadNotifications.filter(n => n.tip_notifikacije === 1).length;
        const classCount = unreadNotifications.filter(n => n.tip_notifikacije === 2).length;

        setTotalNotifications(unreadNotifications.length);
        setMessageNotifications(messageCount);
        setClassNotifications(classCount);
      });
    }

    // Očisti socket događaje kada se komponenta demontira ili kada se korisnik promeni
    return () => {
      socket.off('newNotification');
      socket.off('unreadNotifications');
    };
  }, [currentUser]);

  //Razmislicemo o ovome:
  
  // Funkcija koja se poziva kada korisnik klikne na "Poruke"
  const handleMessagesClick = () => {
    if (currentUser) {
      // Emituj događaj koji označava sve notifikacije tipa 1 kao pročitane
      //socket.emit('markAsRead', { receiverId: currentUser.id_korisnika, tip_notifikacije: 1 });
      //setTotalNotifications(totalNotifications-messageNotifications)
     // setMessageNotifications(0); // Resetuj broj poruka
      
    }
  };
  


  // Funkcija koja se poziva kada korisnik klikne na "Časovi"
  const handleClassesClick = () => {
    if (currentUser) {
      // Emituj događaj koji označava sve notifikacije tipa 2 kao pročitane
      socket.emit('markAsRead', { receiverId: currentUser.id_korisnika, tip_notifikacije: 2 });
      setTotalNotifications(totalNotifications-classNotifications)
      setClassNotifications(0); // Resetuj broj časova
    }
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between headerwdth">
        <NavLink to="/" className="logo d-flex align-items-center me-auto me-lg-0">
          <img src="/assets/images/logo.png" alt="logo" />
        </NavLink>
        
        <nav id="navbar" className="navbar">
          <ul>
            {currentUser && currentUser.id_tipa === 1 && (
              <li>
                <NavLink to="/admin-dashboard">Admin</NavLink>
              </li>
            )}
            <li><NavLink to="/">Početna</NavLink></li>
            <li className="dropdown">
              <a href="predmeti"><span>Predmeti</span><i className="bi bi-chevron-down dropdown-indicator" /></a>
              <ul>
                  <li><NavLink to="/predmeti">Svi predmeti</NavLink></li>
                  <li className="dropdown"><a href="#proba"><span>Jezici</span><i className="bi bi-chevron-down dropdown-indicator" /></a>
                    <ul>
                      {predmeti.map(predmet => ( //prikaz predmeta dje je tip = jezik
                          predmet.tip === 2 && (
                            <li key={predmet.id_predmeta}>
                              <NavLink to={`/predmeti/${predmet.link}`} className="hide-header">
                                {predmet.naziv_predmeta}
                              </NavLink>
                            </li>
                          )
                        ))}
                    </ul>
                  </li>
                    {predmeti.map(predmet => predmet.tip === 1 && (
                      <li key={predmet.id_predmeta}>
                        <NavLink to={`/predmeti/${predmet.link}`}>{predmet.naziv_predmeta}</NavLink>
                      </li>
                    ))}
              </ul>
            </li>
            {!currentUser && (
              <li><NavLink to="/postani-profesor">Postani profesor</NavLink></li>
            )}
            <li><NavLink to="/#contact" className='disable-active' onClick={handleContactClick}>Kontakt</NavLink></li>
          </ul>
        </nav>

        {currentUser && (
          <div className="dropdown text-end">
            <a href="#proba" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              {currentUser.img ? (
                <img src={currentUser.img} alt="Profile" width={32} height={32} className="rounded-circle" />
              ) : (
                <img src="/assets/images/student-logo.png" alt="Default" width={32} height={32} className="rounded-circle" />
              )}
              {totalNotifications > 0 && (
                <span className="notification-badge">{totalNotifications}</span>
              )} {/* Ukupan broj notifikacija */}
            </a>
            <ul className="dropdown-menu text-small">
              <li>
                <NavLink to="/dashboard" className="dropdown-item" onClick={handleClassesClick}>
                  Časovi
                  {classNotifications > 0 && (
                    <span className="notification-badge">{classNotifications}</span>
                  )} {/* Broj notifikacija za časove */}
                </NavLink>
              </li>
              <li>
                <NavLink to="/poruke" className="dropdown-item" onClick={handleMessagesClick} >
                  Poruke
                  {messageNotifications > 0 && (
                    <span className="notification-badge">{messageNotifications}</span>
                  )} {/* Broj notifikacija za poruke */}
                </NavLink>
              </li>
              <li><NavLink to="/podesavanja" className="dropdown-item">Podešavanja</NavLink></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href="#proba" onClick={logout}>Odjavi se</a></li>
            </ul>
          </div>
        )}

        {!currentUser && <NavLink className="btn-login" to="/login">Prijava</NavLink>}
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
      </div>
    </header>
  );
};

export default Header;
