import React from "react";

const Footer = () =>{
    return(
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-lg-3 col-md-6 d-flex">
                            <i className="bi bi-geo-alt icon" />
                            <div>
                                <h4>PesaRatke</h4>
                                <p>A108 Adam Street <br />New York, NY 535022 - US<br /></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-telephone icon" />
                            <div>
                                <h4>Kontakt</h4>
                                <p><strong>Phone:</strong> +382 67 123 456<br /><strong>Email:</strong> proba@gmail.com<br /></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-clock icon" />
                            <div>
                            <h4>Zakažite već sada</h4>
                            <p><strong>Mon-Sat: 11AM</strong> - 23PM<br />Sunday: Closed</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Zapratite nas</h4>
                            <div className="social-links d-flex">
                                <a href="#proba" className="twitter"><i className="bi bi-twitter" /></a>
                                <a href="#proba" className="facebook"><i className="bi bi-facebook" /></a>
                                <a href="#proba" className="instagram"><i className="bi bi-instagram" /></a>
                                <a href="#proba" className="linkedin"><i className="bi bi-linkedin" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">© Copyright<strong><span>PesaRatke</span></strong>. Sva prava zadržana</div>
                </div>
            </footer>
    );
};

export default Footer;