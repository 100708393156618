import { useState } from 'react';

const useModal = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        heading: '',
        message: '',
        type: '',
        onConfirm: null,
    });

    const showModal = (heading, message, type) => {
        setModalContent({ heading, message, type });
        setModalVisible(true);
    };

    const showConfirmationModal = (heading, message, onConfirm) => {
        setModalContent({ heading, message, type: 'confirmation', onConfirm });
        setModalVisible(true);
    };

    const showDeclineClassTutorModal = (heading, message, onSubmit) => {
        setModalContent({ 
            heading, 
            message, 
            type: 'decline-class-tutor', 
            onSubmit 
        });
        setModalVisible(true);
    };
    

    const handleFormSuccess = (message) => {
        showModal('Bravo!', message, 'success');
    };

    const handleFormFailure = (message) => {
        showModal('Ups!', message, 'failure');
    };

    const handleCloseModal = () => {
        //setComment("");
        setModalVisible(false);
    };

    return {
        modalVisible,
        modalContent,
        handleFormSuccess,
        handleFormFailure,
        handleCloseModal,
        showConfirmationModal, 
        setModalVisible,
        showDeclineClassTutorModal,
    };
};

export default useModal;
