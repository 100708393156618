import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import useScrollToSection from '../hooks/useScrollToSection';
import Modal from '../components/Modal';
import useModal from '../hooks/useModal';

const HomePage = () => {
  const contactRef = useRef(null);
  const handleContactClick = useScrollToSection('contact');
  const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();
  
  useEffect(() => {
    if (window.location.hash === '#contact') {
      contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/auth/send-email', formData);
      if (response.status === 200) {
        // console.log('Poruka uspješno poslata:', response.data);
        handleFormSuccess("Poruka uspješno poslata!");
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
          });
        // Show success message to the user
      }
    } catch (error) {
      // console.error('Greška prilikom slanja poruke:', error);
      handleFormFailure("Greška prilikom slanja poruke!");
      // Show error message to the user
    }
  };


  return (
    <>
     {modalVisible && <Modal {...modalContent} onClose={handleCloseModal} />}
    <main id="main">
        <section id="hero" className="hero d-flex align-items-center section-bg-w">
            <div className="container">
                <div className="row justify-content-between gy-5">
                    <div className="col-lg-5 order-1 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                        <h2 data-aos="fade-up" className="aos-init aos-animate">Zakažite svoj čas na vrijeme<br />Brzo i jednostavno</h2>
                        <p data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate">Sed autem laudantium dolores. Voluptatem itaque ea consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
                        <div className="d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                            <NavLink to="/login" className="hero-a-btn">Prijavite se</NavLink>
                        </div>
                    </div>
                    <div className="col-lg-6 order-2 order-lg-2 text-center text-lg-start">
                        <img src="/assets/images/teacher.jpg" className="img-fluid aos-init aos-animate" alt="" data-aos="zoom-out" data-aos-delay={300}/>
                    </div>
                </div>
            </div>
        </section>
        <section id="why-us" className="why-us section-bg">
            <div className="container" data-aos="fade-up">
                <div className="row gy-4">
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
                        <div className="why-box">
                            <h3>Želite da se pridružite ?</h3>
                            <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt, exercitationem. Enim inventore tenetur alias. Ad iure quod dolores, nam magni amet odio veniam facere repellat praesentium dolorum animi obcaecati saepe. Lorem ipsum dolor sit amet.</p>
                            <div className="text-center">
                                <NavLink to="/#contact" className="more-btn" onClick={handleContactClick}>Kontaktirajte nas <i className="bx bx-chevron-right" /></NavLink>
                            </div>
                        </div>
                    </div>
               
                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="row gy-4">
                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay={200}>
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-clipboard-data" />
                                    <h4>100</h4>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.Dolorem distinctio obcaecati possimus nihil doloribus, aliquidillum autem molestias!</p>
                                </div>
                            </div>
                   
                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay={300}>
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="icofont-food-basket" />
                                    <h4>70</h4>
                                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas minima, harum ut odio impedit veniam maxime explicabo. </p>
                                </div>
                            </div>
                        
                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay={400}>
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="icofont-holding-hands" />
                                    <h4>50%</h4>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.Officiis expedita, nostrum sit cupiditate iste nulla? Dolorum,eligendi?</p>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" className="contact" ref={contactRef}>
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Kontakt</h2>
                    <p>Dodatna pitanja ? <span>Kontaktirajte nas</span></p>
                </div>
                <div className="row gy-4">
                    <div className="col-md-6">
                        <div className="info-item d-flex align-items-center">
                            <i className="icon bi bi-envelope flex-shrink-0" />
                            <div>
                                <h3>Pošaljite Email</h3>
                                <p>dasenebaci@outlook.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-item  d-flex align-items-center">
                            <i className="icon bi bi-telephone flex-shrink-0" />
                            <div>
                                <h3>Pozovite nas</h3>
                                <p>+382 67 955 832 | +382 69 851 872 | +382 69 018 828</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <ContactForm/> */}
                  <form onSubmit={handleSubmit} className="contact-email-form p-3 p-md-4 home">
                  <div className="row">
                    <div className="col-xl-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Vaše ime"
                        required
                        autoComplete="on"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-xl-6 form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Vaš Email"
                        required
                        autoComplete="on"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Povod"
                      required
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Poruka"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit">Pošalji poruku</button>
                  </div>
                </form>
            </div>
        </section>
    </main>
    </>
);
};

export default HomePage;
