import { Link } from 'react-router-dom';

const SubjectList = ({ predmeti }) => {
    return (
        <div className="row">
    
            {predmeti.map(predmet => (
                    <Link key={predmet.id_predmeta} to={`/predmeti/${predmet.link}`} className='col-lg-4'>
                        <div className="subject-box d-flex justify-content-center align-items-center">
                                <h4>{predmet.naziv_predmeta}</h4>
                        </div>
                    </Link>
            ))}

        </div>
        
    );
}

export default SubjectList;