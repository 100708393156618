import { useEffect, useState } from "react";
import axios from "axios";
import Modal from './Modal';
import useModal from '../hooks/useModal';
import { DateTime } from 'luxon';

const ReservationList = ({ idKorisnika, tipKorisnika }) => {
    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal, showConfirmationModal, showDeclineClassTutorModal } = useModal();
    const [comment, setComment] = useState("");
    const [rezervacije, setReservations] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredReservations, setfilteredReservations] = useState([]); // Store filtered users
    const [reservationType, setReservationType] = useState(1);

    useEffect(() => {
        const fetchReservations = async () => {
            setReservations([]); 
            setfilteredReservations([]);
            
            try {
                let response;
                if (tipKorisnika === 3) {
                    response = await axios.get(`/api/reservation/ucenik/${idKorisnika}`);
                } else {
                    response = await axios.get(`/api/reservation/profesor/${idKorisnika}`);
                }
    
                //ovo je gpt kuca uglvnm da prikazujem rezervacije od juce pa nadalje,
                // Current date and calculate yesterday's date
                // const today = new Date();
                // const yesterday = new Date(today);
                // yesterday.setDate(today.getDate() - 2); // subtract one day to get yesterday
                
                // // Filter reservations based on the date (from yesterday onward)
                // const filteredReservations = response.data.filter(rezervacija => {
                //     const reservationDate = new Date(rezervacija.datum);
                //     return reservationDate >= yesterday;  // keep only reservations from yesterday or later
                // });
    
                setReservations(response.data);
                // setfilteredReservations(response.data);
                setfilteredReservations(response.data.filter(rezervacija => rezervacija.status === 1)); //prvo prikazem ove na cekanju
            
            } catch (err) {
                console.error("Greška pri učitavanju rezervacija!", err);
            }
        };
        fetchReservations();
    }, [reload, tipKorisnika, idKorisnika]);

    const executeAction = async (rezervacija, noviStatus) => {
        try {
            const { id} = rezervacija;
            await axios.put(`/api/reservation/update/${id}`, { status: noviStatus });
            handleFormSuccess("Status rezervacije uspješno ažuriran!");
        } catch (err) {
            handleFormFailure("Greška prilikom ažuriranja statusa rezervacije.");
        }
        setReload(prev => !prev);
    };

    const executeActionWithComment = async (rezervacija, noviStatus, comment) => {
        if(!comment || comment.trim() === ""){
            alert('Molimo Vas da unesete razlog za otkazivanje časa.');
            return;
        }
        try {
            const { id } = rezervacija;
            await axios.put(`/api/reservation/update/${id}`, { status: noviStatus, comment });
            handleFormSuccess("Status rezervacije uspješno ažuriran!");
            setComment("");
        } catch (err) {
            handleFormFailure("Greška prilikom ažuriranja statusa rezervacije.");
        }
        setReload(prev => !prev);
    };

    const handleAction = (rezervacija, noviStatus) => {
        if(noviStatus === 2){
            if (tipKorisnika === 2) {
                showDeclineClassTutorModal("Potvrda",`Molimo Vas da unesete razlog za otkazivanje časa:`, (comment) => executeActionWithComment(rezervacija, noviStatus, comment));
            }else{
                showConfirmationModal("Potvrda",`Da li ste sigurni da želite da otkažete čas?`,() => executeAction(rezervacija, noviStatus));    
            } 
        }else if(noviStatus === 3){
            showConfirmationModal("Potvrda",`Da li ste sigurni da želite da odobrite čas?`,() => executeAction(rezervacija, noviStatus)); 
        }else{
            showConfirmationModal("Potvrda",`Da li ste sigurni da želite da označite čas kao održan?`,() => executeAction(rezervacija, noviStatus)); 
        }
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('T')[0].split('-');
        return `${day}.${month}.${year}`;
    };

    const formatDay = (dayString) => {
        const days = {
            Pon: 'Ponedeljak',
            Uto: 'Utorak',
            Sri: 'Srijeda',
            Čet: 'Četvrtak',
            Pet: 'Petak',
            Sub: 'Subota',
            Ned: 'Nedelja'
        };
        return days[dayString] || dayString;
    };
    

    const isTimePastOneHour = (reservationDateString, reservationTime) => {
        // Get the current date and time in the Europe/Podgorica timezone
        const currentDate = DateTime.now().setZone('Europe/Podgorica'); // Correct for current time
    
        const reservationDate = DateTime.fromISO(reservationDateString) // No need to specify time zone again
            .set({ hour: parseInt(reservationTime.split(':')[0], 10), minute: parseInt(reservationTime.split(':')[1], 10) });
        
            const timeDifference = currentDate.diff(reservationDate, 'milliseconds').milliseconds;

        // Return true if the difference is greater than or equal to 1 hour (3600000 milliseconds)
        return timeDifference >= 3600000; // 1 hour = 3600000 milliseconds
    };
    
    const filterByReservationType = (type) => {
        if (type === null) {
          setfilteredReservations(rezervacije); // No filter, show all users
        } else {
          setfilteredReservations(rezervacije.filter(rezervacija => rezervacija.status === type));
        }
        setReservationType(type);
    };

    
    return (
        <>
        {modalVisible && <Modal {...modalContent} comment={comment} setComment={setComment} onClose={handleCloseModal} />}
        <div className="row justify-content-center reservation-containter">
            
            <div className="filter-buttons-class">
              <button className={reservationType===1?"active":""} onClick={() => filterByReservationType(1)}>Na čekanju</button>
              <button className={reservationType===2?"active":""} onClick={() => filterByReservationType(2)}>Otkazani</button>
              <button className={reservationType===3?"active":""} onClick={() => filterByReservationType(3)}>Potvrdjeni</button>
              <button className={reservationType===4?"active":""} onClick={() => filterByReservationType(4)}>Održani</button>
              <button className={reservationType===null?"active":""} onClick={() => filterByReservationType(null)}>Prikaži sve</button>
              {/* <h3>{userTypeFilter}</h3> */}
            </div>

            {filteredReservations.map((rezervacija, index) => (
                <div key={index} className="reservation-box col-6 tutor-box d-flex">
                    {rezervacija.status === 1 && <span className="status-info wait">Na čekanju</span>}
                    {rezervacija.status === 2 && <span className="status-info canceled">Otkazan</span>}
                    {rezervacija.status === 3 && <span className="status-info approved">Potvrđen</span>}
                    {rezervacija.status === 4 && <span className="status-info done">Održan</span>}
                    <div className='info px-0 w-100'>
                        <div className='d-flex align-items-center w-flex justify-content-center jn gap-3'>
                            <img src={rezervacija.img || "/assets/images/student-logo.png"} alt="profile" className="rounded-circle" />
                            <h3>{rezervacija.ime}</h3>
                        </div>
                        <div className='d-flex align-items-baseline w-flex'>
                            <h4 style={{ marginRight: '10px' }}>Termin:</h4>
                            <p>{formatDay(rezervacija.dan) + ", " + formatDate(rezervacija.datum) + " u " + rezervacija.sat + 'h'}</p>
                        </div>
                        <div className='d-flex align-items-baseline w-flex'>
                            <h4 style={{ marginRight: '10px' }}>Predmet:</h4>
                            <p>{rezervacija.predmet + " za nivo - " + rezervacija.naziv_nivoa}</p>
                        </div>

                        {/* Buttons for professor */}
                        {tipKorisnika === 2 && rezervacija.status === 1 && (
                            <>
                                <button className='approve-btn' onClick={() => handleAction(rezervacija, 3)}>Odobri čas</button>
                                <button className='deny-btn' onClick={() => handleAction(rezervacija, 2)}>Otkaži čas</button>
                            </>
                        )}
                        {tipKorisnika === 2 && rezervacija.status === 3  && isTimePastOneHour(rezervacija.datum,rezervacija.sat) &&(
                            <button className='done-btn' onClick={() => handleAction(rezervacija, 4)}>Označi kao završen</button>
                        )}


                        {/* Button for student */}
                        {tipKorisnika === 3 && (rezervacija.status === 1 || rezervacija.status === 3 )&& (
                            <button className='deny-btn' onClick={() => handleAction(rezervacija, 2)}>Otkaži čas</button>
                        )}
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

export default ReservationList;
