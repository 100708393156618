const useTutorReviews = () => {

    // Funkcija za formatiranje teksta recenzija (1 recenzija, 2-4 recenzije, 5+ recenzija)
    const getReviewText = (number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;
        
        if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
            return `${number} recenzija`; // Izuzetak za 11-14
        } else if (lastDigit === 1) {
            return `${number} recenzija`;
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return `${number} recenzije`;
        } else {
            return `${number} recenzija`;
        }
    };

    // Funkcija za dobijanje broja punih zvjezdica na osnovu prosječne ocjene
    const getStars = (prosjek) =>{ //funkcija koja u zavisnosti od prosjeka racuna pune zvjezdice
        if(prosjek >= 4.5){
            return (<><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></>)
        }else if(prosjek < 4.5 && prosjek >= 3.5 ){
            return (<><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star"></i></>)
        }else if(prosjek < 3.5 && prosjek >= 2.5){
            return (<><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star"></i><i className="bi bi-star"></i></>)
        }else if(prosjek < 2.5 && prosjek >= 1.5){
            return (<><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i></>)
        }else if(prosjek < 1.5 && prosjek >= 0.5){
            return (<><i className="bi bi-star-fill"></i><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i></>)
        }else{
            return (<><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i><i className="bi bi-star"></i></>)
        }
    }

    return { getReviewText, getStars };
};

export default useTutorReviews;
