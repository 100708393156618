import React from 'react';

const Modal = ({ heading, message, type, onClose, onConfirm, comment, setComment, onSubmit }) => {
    
    const handleSubmit = () => {
        onSubmit(comment);
        setComment(""); 
    };
    
    return (
        <div className="modal-overlay">
            <div className={`modal-content ${type}`}>
                <h2>{heading}</h2>
                <p>{message}</p>
                <i className="bi bi-x-square" onClick={() => {
                     if (type === 'decline-class-tutor') {
                        setComment("");
                    }
                    onClose();
                }}></i>

                {type === 'confirmation' && 
                    <div>
                        <button onClick={onConfirm}>Da</button>
                        <button onClick={onClose}>Ne</button>
                    </div>
                }

                {/* Show comment box and submit button only for 'decline-class-tutor' type */}
                {type === 'decline-class-tutor' && (
                    <div>
                        <div className='d-flex align-items-center justify-content-center w-flex mb-2 mt-2'>
                            <label htmlFor="komentar" className='mx-2 mb-2'>Razlog:</label>
                            <textarea 
                                id="komentar"
                                value={comment}
                                rows="2"
                                cols="30"
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Napišite razlog"
                            />
                        </div>
                        <div>

                            <button onClick={handleSubmit}>Pošalji</button>
                            <button onClick={onClose}>Otkaži</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
