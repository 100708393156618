import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/authContext'
const useForm = (formType, onSuccess, onFailure) => {
    const {login} = useContext(AuthContext);

    const navigate = useNavigate(); // Initialize useNavigate

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        dob: '',
        phone: '',
        cv: '', // Only for professors
    });

    const handleInputChange = (e) => {
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //provjera je li ime i prezime u dobrom formatu
        if ((formType === 'register-teacher' || formType === 'register-student')) {
            const namePattern = /^[A-Za-z]+ [A-Za-z]+$/;  // Regex za ime prezime
            if (!namePattern.test(formData.name.trim())) {
                onFailure('Polje za ime i prezime mora sadržati dvije riječi, odvojene razmakom.');
                return;
            }
        }

        //provjera passworda
        if ((formType === 'register-teacher' || formType === 'register-student')) {
            const password = formData.password.trim();
            
            // Provera da li lozinka ima manje od 6 karaktera
            if (password.length < 6) {
                onFailure('Lozinka mora imati najmanje 6 karaktera.');
                return;
            }
            
            // Regularni izrazi za provjeru uslova
            const hasUpperCase = /[A-Z]/.test(password); // Provjera za veliko slovo
            const hasNumber = /\d/.test(password);       // Provjera za broj
            const hasSpecialChar = /[!@#%&*?+=._-]/.test(password); // Provjera za specijalni karakter
            
            // Ako bilo koji uslov nije zadovoljen, prijavi grešku
            if (!hasUpperCase || !hasNumber || !hasSpecialChar) {
                onFailure('Lozinka mora sadržati barem jedno veliko slovo, broj i specijalni karakter (!, @, #, itd...)');
                return;
            }
        }
        
        //provjera jel sve popunjeno
        if ((formType === 'register-teacher' || formType === 'register-student') && 
            (!formData.name.trim() || !formData.email.trim() || !formData.password.trim() || !formData.dob.trim() || !formData.phone.trim())) {
                onFailure('Molimo Vas da unesete sva potrebna polja.');
                return;
        }
        
        //biografija    
        if (formType === 'register-teacher' && !formData.cv.trim()){
            onFailure('Molimo Vas da unesete sva potrebna polja.');
            return;
        }

        // jel prifatio politiku ovog vrhunskog projekta
        if (formType !== 'login') {
            const policyAccepted = document.getElementById('policy').checked;
            if (!policyAccepted) {
                onFailure('Morate prihvatiti uslove korišćenja i politiku privatnosti.');
                return;
            }
        }

        let dataToSend = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            dob: formData.dob,
            phone: formData.phone,
        };

        if (formType === 'register-teacher') {
            dataToSend.cv = formData.cv;
        }

        try {
            //console.log(dataToSend)
            const endpoint = formType === 'login' ? '/api/auth/login' : "/api/auth/register";
            if( formType === 'login')
               
                await login(formData)
            
            // console.log(endpoint)
            const response = await axios.post(endpoint, formData);
            //console.log(response)

            if (formType === 'login') {
                console.log('Login successful:', response.data);
                // onSuccess('Login successful!');

                if(response.data.id_tipa === 1){
                    navigate("/admin-dashboard");
                }else{
                    navigate("/dashboard");
                }
            } else {
                console.log('Registration successful:', response.data);
                onSuccess(response.data.message || 'Uspješno ste se registrovali, idite na prijavu!');
                //navigate("/");
            }
        } catch (error) {
            const message = error.response?.data?.message || error.message;
            onFailure(`Greška: ${message}`);
        }
    };

    return {
        formData,
        handleInputChange,
        handleSubmit
    };
};

export default useForm;
