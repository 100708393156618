import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('users'); 
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // Store filtered users
  const [reservations, setReservations] = useState([]); 
  const [openUserId, setOpenUserId] = useState(null); 
  const [userTypeFilter, setUserTypeFilter] = useState(null); // Filter state

  const fetchUsers = async () => {
    try {
      const res = await axios.get('/api/auth/get/all/users');
      setUsers(res.data);
      setFilteredUsers(res.data); // Initialize filteredUsers with all users
    } catch (err) {
      console.error('Error fetching users:', err);
    }
  };

  const fetchReservations = async () => {
    try {
      const res = await axios.get('/api/auth/get/all/reservations');
      setReservations(res.data);
    } catch (err) {
      console.error('Error fetching reservations:', err);
    }
  };

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    } else if (activeTab === 'reservations') {
      fetchReservations();
    }
  }, [activeTab]);

  const toggleVerification = async (userId, isVerified) => {
    Swal.fire({
      title: 'Da li ste sigurni?',
      text: `Želite da ${isVerified ? 'uklonite' : 'dodate'} verifikaciju za ovog korisnika?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da, potvrdite!',
      cancelButtonText: 'Otkaži'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('/api/auth/verifyUser', {
            userId,
            isVerified: isVerified ? 0 : 1,
          });

          fetchUsers();

          Swal.fire(
            'Uspešno!',
            `Korisnik je ${isVerified ? 'ne' : ''}verifikovan.`,
            'success'
          );
        } catch (err) {
          console.error('Error verifying user:', err);
          Swal.fire('Greška!', 'Došlo je do greške prilikom verifikacije.', 'error');
        }
      }
    });
  };

  const toggleBiography = (userId) => {
    setOpenUserId(openUserId === userId ? null : userId);
  };

  // Filter function for user type
  const filterByUserType = (type) => {
    setUserTypeFilter(type);
    if (type === null) {
      setFilteredUsers(users); // No filter, show all users
    } else {
      setFilteredUsers(users.filter(user => user.id_tipa === type));
    }
  };

  // Utility function for formatting the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="admin-dashboard">
      <div className="sidebar">
        <h2>Admin Panel</h2>
        <ul>
          <li
            className={activeTab === 'users' ? 'active' : ''}
            onClick={() => setActiveTab('users')}
          >
            Pregled korisnika
          </li>
          <li
            className={activeTab === 'reservations' ? 'active' : ''}
            onClick={() => setActiveTab('reservations')}
          >
            Pregled rezervacija
          </li>
        </ul>
      </div>
      <div className="content">
        {activeTab === 'users' && (
          <div>
            <h2>Pregled korisnika</h2>
            {/* Filter buttons */}
            <div className="filter-buttons">
              <button onClick={() => filterByUserType(2)}>Profesori</button>
              <button onClick={() => filterByUserType(3)}>Učenici</button>
              <button onClick={() => filterByUserType(null)}>Prikaži sve</button>
              <h3>{userTypeFilter}</h3>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Ime</th>
                  <th>Email</th>
                  <th>Datum rođenja</th>
                  <th>Broj telefona</th>
                  <th>Tip korisnika</th>
                  <th>Slika</th>
                  <th>Verifikovan (Admin)</th>
                  <th>Email Verifikovan</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <React.Fragment key={user.id_korisnika}>
                    <tr>
                      <td
                        onClick={() => toggleBiography(user.id_korisnika)}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        {user.ime}
                      </td>
                      <td>{user.email}</td>
                      <td>{formatDate(user.datum_rodjenja)}</td>
                      <td>{user.broj_telefona}</td>
                      <td>{user.id_tipa === 2 ? 'Profesor' : 'Učenik'}</td>
                      <td>
                        {user.img ? (
                          <img
                            src={`${user.img}`}
                            alt="Profil slika"
                            width={32}
                            height={32}
                            className="rounded-circle"
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </td>
                      <td
                        onClick={() => toggleVerification(user.id_korisnika, user.verifikovan)}
                        style={{ cursor: 'pointer', color: user.verifikovan ? 'green' : 'red' }}
                      >
                        {user.verifikovan ? 'Da' : 'Ne'}
                      </td>
                      <td>{user.isVerified ? 'Da' : 'Ne'}</td>
                    </tr>
                    {openUserId === user.id_korisnika && (
                      <tr>
                        <td colSpan="8" className="bio-container">
                          <strong>Biografija:</strong> {user.biografija || 'Nema biografije'}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Reservation Tab */}
        {activeTab === 'reservations' && (
          <div>
            <h2>Pregled rezervisanih termina</h2>
            <table>
              <thead>
                <tr>
                  <th>ID Profesora</th>
                  <th>Profesor</th>
                  <th>ID Ucenika</th>
                  <th>Učenik</th>
                  <th>Predmet</th>
                  <th>Nivo</th>
                  <th>Datum</th>
                  <th>Dan</th>
                  <th>Sat</th>
                  <th>Status</th>
                  <th>Ime Status</th>
                  <th>Vrijeme zakazivanja</th>
                </tr>
              </thead>
              <tbody>
                {reservations.map((reservation) => (
                  <tr key={reservation.id}>
                    <td>{reservation.id_profesora}</td>
                    <td>{reservation.ime_profesora}</td>
                    <td>{reservation.id_ucenika}</td>
                    <td>{reservation.ime_ucenika}</td>
                    <td>{reservation.predmet}</td>
                    <td>{reservation.nivo}</td>
                    <td>{formatDate(reservation.datum)}</td>
                    <td>{reservation.dan}</td>
                    <td>{reservation.sat}</td>
                    <td>{reservation.status}</td>
                    <td>{reservation.ime_statusa}</td>
                    <td>{formatDate(reservation.vrijeme_zakazivanja)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
