import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import useModal from '../hooks/useModal';

const TutorSubjSettings = ({ profesorId }) => {
    const [predmeti, setPredmeti] = useState([]);
    const [selectedPredmeti, setSelectedPredmeti] = useState({});
    const [reload, setReload] = useState(false);

    const [cijene, setCijene] = useState({
        osnovna: '',
        srednja: '',
        fakultet: ''
    });
    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();

    // Fetch all subjects when component mounts
    useEffect(() => {
        const fetchPredmeti = async () => {
            try {
                const response = await axios.get('/api/subj/predmeti');
                setPredmeti(response.data);
            } catch (err) {
                console.error('Error fetching subjects:', err);
            }
        };
        const fetchSelectedPredmeti = async () => {
            const nivoMap = {1: 'osnovna',2: 'srednja',3: 'fakultet'};
            
            try {
                const response = await axios.get(`/api/tutor/profesor_predmeti/${profesorId}`);
                const profesorPredmeti = response.data;

                const selected = profesorPredmeti.reduce((acc, item) => {
                    if (!acc[item.id_predmeta]) {
                        acc[item.id_predmeta] = {};
                    }
                    
                    const nivoKey = nivoMap[item.id_nivoa];
                    if (nivoKey) {
                        acc[item.id_predmeta][nivoKey] = true; // Set the level as selected
                    }
                    return acc;
                }, {});

                setSelectedPredmeti(selected);
            } catch (err) {
                console.error('Error fetching profesor_predmeti:', err);
            }
        };

        const fetchCijene = async () => {
            try {
                const response = await axios.get(`/api/tutor/cijene/${profesorId}`);
                const cijeneData = response.data;
        
                const updatedCijene = {
                    osnovna: cijeneData.find(item => item.id_nivoa === 1)?.nivo_cijena || '',
                    srednja: cijeneData.find(item => item.id_nivoa === 2)?.nivo_cijena || '',
                    fakultet: cijeneData.find(item => item.id_nivoa === 3)?.nivo_cijena || ''
                };
        
                setCijene(updatedCijene);
            } catch (err) {
                console.error('Error fetching cijene:', err);
            }
        };
        

        fetchCijene();
        fetchSelectedPredmeti();
        fetchPredmeti();
    }, [profesorId, reload]);


    // Handle change for fixed price input
    const handleCijenaChange = (e) => {
        const { name, value } = e.target;
        setCijene(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle subject selection
    const handlePredmetChange = (id_predmeta, nivo) => {
        setSelectedPredmeti(prev => ({
            ...prev,
            [id_predmeta]: {
                ...prev[id_predmeta],
                [nivo]: !prev[id_predmeta]?.[nivo] // Toggle the selected level
            }
        }));
    };

  
    const handleSaveClick = async () => {
        const nivoMap = {1: 'osnovna',2: 'srednja',3: 'fakultet'};
        const selectedData = [];


        // Prepare data for saving if all prices are valid
        for (const [id_predmeta, nivoi] of Object.entries(selectedPredmeti)) {
            for (const [nivo, isSelected] of Object.entries(nivoi)) {
                if (isSelected) {
                    const cijena = cijene[nivo];
                    if (!cijena || cijena <= 0) { // Check if the price is missing or invalid
                        handleFormFailure(`Unesite cijenu za sve nivoe koje ste izabrali!`);
                        setReload(prev => !prev);
                        return; // Stop the save process if any price is invalid
                    }
                    selectedData.push({
                        id_profesora: profesorId,
                        id_predmeta: id_predmeta,
                        id_nivoa: Object.keys(nivoMap).find(key => nivoMap[key] === nivo),
                        cijena: cijene[nivo]
                    });
                }
            }
        }

        try {
            // Send data to the backend
            await axios.post('/api/tutor/sacuvaj_predmete', {
                profesorId,
                predmeti: selectedData
            });
            handleFormSuccess("Podaci su uspješno sačuvani!");
        } catch (err) {
            console.error('Error saving subjects:', err);
            handleFormFailure("Greška prilikom čuvanja podataka, provjerite li da li su cijene ispravno unešene!");
        }
    };


    return (
        <>
            {modalVisible && <Modal {...modalContent} onClose={handleCloseModal}/>} 
        <div className="settings-container tutor-box contact">
            <div className="settings-header">
                <h2>Upišite Vaše cijene (samo broj) i predmete koje predajete, A onda klik na sačuvaj!</h2>
            </div>

            {/* Cijene za osnovna, srednja i fakultet */}
            
            <div className="contact-email-form register-tutor" style={{ margin: '0 auto', boxShadow:'none' }}>
            
                <div className="form-group">
                    <h4>Cijena za osnovnu školu(€): </h4>
                    <input className='form-control' type="number" name="osnovna" value={cijene.osnovna} onChange={handleCijenaChange} min="0" step="1"/>
                </div>
                <div className="form-group">
                    <h4>Cijena za srednju školu(€): </h4>
                    <input className='form-control' type="number" name="srednja" value={cijene.srednja} onChange={handleCijenaChange} min="0" step="1"/>
                </div>
                <div className="form-group">
                    <h4>Cijena za fakultet(€): </h4>
                    <input className='form-control' type="number" name="fakultet" value={cijene.fakultet} onChange={handleCijenaChange} min="0" step="1"/>
                </div>
            </div>

            {/* Lista predmeta sa nivoima */}
            <div>
                {predmeti.map(predmet => (
                    <div key={predmet.id_predmeta} className='d-flex w-flex align-items-center' style={{marginBottom:'10px'}}>
                        <h4 style={{marginBottom:'0'}}>{predmet.naziv_predmeta}</h4>
                        <div>
                            <label style={{fontSize:'20px'}}>
                                <input style={{margin: '0 5px 0 15px'}}
                                    type="checkbox"
                                    name='osnovnaInp'
                                    checked={selectedPredmeti[predmet.id_predmeta]?.osnovna || false}
                                    onChange={() => handlePredmetChange(predmet.id_predmeta, 'osnovna')}
                                />
                                Osnovna škola
                            </label>
                            <label style={{fontSize:'20px'}}>   
                                <input style={{margin: '0 5px 0 15px'}}
                                    type="checkbox"
                                    name='srednjaInp'
                                    checked={selectedPredmeti[predmet.id_predmeta]?.srednja || false}
                                    onChange={() => handlePredmetChange(predmet.id_predmeta, 'srednja')}
                                />
                                Srednja škola
                            </label>
                            <label style={{fontSize:'20px'}}>
                                <input style={{margin: '0 5px 0 15px'}}
                                    type="checkbox"
                                    name='faksInp'
                                    checked={selectedPredmeti[predmet.id_predmeta]?.fakultet || false}
                                    onChange={() => handlePredmetChange(predmet.id_predmeta, 'fakultet')}
                                />
                                Fakultet
                            </label>
                        </div>
                    </div>
                ))}
            </div>

            <button className='btn-tutor' style={{position:'static', marginTop:'35px'}} onClick={handleSaveClick}>Sačuvaj</button>
        </div>
        </>
    );
};

export default TutorSubjSettings;
