import React from 'react';
import axios from 'axios';
import useScheduler from '../hooks/useScheduler';
import Modal from './Modal';
import useModal from '../hooks/useModal';
import {AuthContext} from "../context/authContext.js"
import { useContext } from 'react';


const Scheduler = ({ onClose, profesorId }) => {
    const {
        // currentDate,
        dayNames, daysOfWeek, selectedSlots,
        predmeti, nivoi, selectedNivo, selectedPredmet, showNivoi,
        timeSlots, handlePrevWeek, handleNextWeek,
        handlePredmetChange, handleNivoChange,
        formatCustomDate, isSlotFree,isSlotReserved,
        isHovered,isSelected,handleMouseEnter,handleSlotClick,handleMouseLeave,duration,setDuration,
        setReload, setSelectedNivo, setSelectedPredmet, setSelectedSlots, setShowNivoi
    } = useScheduler(profesorId);

    const {currentUser}= useContext(AuthContext)
    const ucenikId = currentUser.id_korisnika;

    const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, handleCloseModal } = useModal();

    const handleZakaziClick = async () => {
        if(duration !== 60 && duration !== 120){
            handleFormFailure("Molimo Vas da izaberite trajanje časa.");
            return;
        }
        if (selectedPredmet === "default") {
            handleFormFailure("Molimo Vas da izaberete predmet.");
            return;
        }

        if (selectedNivo === "default") {
            handleFormFailure("Molimo Vas da izaberite nivo.");
            return;
        }

        if (!selectedSlots || selectedSlots.length === 0) {
            handleFormFailure("Molimo Vas da izaberete termin.");
            return;
        }
    
        // ODJE bi mozda dobro bilo dodat jos jedan modal, sa opcijom da ili ne i dje pise potvrdi termin za : podaci
        try {
            const response = await axios.post('/api/planer/rezervisi', {
                profesorId,
                ucenikId,
                predmet: selectedPredmet,
                nivo: selectedNivo,
                termini: selectedSlots,
            });
            handleFormSuccess(response.data.message || 'Vaš termin je uspješno zakazan!');
            setReload(prev => !prev);
            setSelectedPredmet("default");
            setSelectedNivo("default");
            setDuration(60);
            setShowNivoi(false);
            setSelectedSlots([]);
        } catch (err) {
            const message = err.response?.data?.message || err.message;
            handleFormFailure(`Greška: ${message}`);
            // handleFormFailure("Greška prilikom zakazivanja termina. Pokušajte ponovo.");
        }
    };


    return (
        <>
        <div className="scheduler-overlay">
        {modalVisible && <Modal {...modalContent} onClose={handleCloseModal} />} 
            <div className="scheduler-modal">
                <i className='bi bi-x-square' onClick={onClose}></i>
                <div className="section-header" style={{ paddingBottom: '0' }}>
                    <h2>Zakazivanje</h2>
                    <p>Zakaži <span>čas</span></p>
                </div>
                <div className="row justify-content-center tutor-filters" style={{ padding: '20px 0 50px 0' }}>
                    <div className="col-lg-6 d-flex flex-column align-items-center text-center flt2">
                        <p>Trajanje časa</p>
                        <div className="choose-time d-flex w-flex">
                            <div className="form-group d-flex me-3">
                                <input type="radio" id="oneHour" name="choosetime"  value={60}  checked={duration === 60}  onChange={() => setDuration(60)}/>
                                <label htmlFor="oneHour" className='ms-1'>60 min</label>
                            </div>
                            <div className="form-group d-flex">
                                <input type="radio" id="twoHour" name="choosetime" value={120} checked={duration === 120} onChange={() => setDuration(120)}/>
                                <label htmlFor="twoHour" className='ms-1'>120 min</label>
                            </div>
                        </div>
                        <div className="section-sort mt-3">
                            <label htmlFor="sortSub">Izaberi predmet:</label>
                            <select name="sortSub" id="sortSub" value={selectedPredmet} onChange={handlePredmetChange}>
                                <option value="default">Izaberi predmet</option>
                                {predmeti.map((predmet, index) => (
                                    <option key={index} value={predmet}>{predmet}</option>
                                ))}
                            </select>
                        </div>

                        {showNivoi && (
                            <div className="section-sort mt-3">
                                <label htmlFor="sort">Izaberi nivo:</label>
                                <select name="sort" id="sort" value={selectedNivo} onChange={handleNivoChange}>
                                    <option value="default">Izaberi nivo</option>
                                    {nivoi.map((nivo, index) => {
                                        if (nivo === 1) {
                                            return (<option key={index} value={nivo}>Osnovna škola</option>);
                                        } else if (nivo === 2) {
                                            return (<option key={index} value={nivo}>Srednja škola</option>);
                                        } else {
                                            return (<option key={index} value={nivo}>Fakultet</option>);
                                        }
                                    })}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
                <div className="scheduler-header">
                    <button className='prev' onClick={handlePrevWeek}><i className="bi bi-chevron-left"></i></button>
                    <span>{formatCustomDate(daysOfWeek[0])} — {formatCustomDate(daysOfWeek[6])}</span>
                    <button className='next' onClick={handleNextWeek}><i className="bi bi-chevron-right"></i></button>
                </div>
                <table className="scheduler-table">
                    <thead>
                        <tr>
                            <th></th>
                            {daysOfWeek.map((day) => (
                                <th key={day}>{dayNames[(day.getDay())]}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {timeSlots.map(slot => (
                            <tr key={slot}>
                                <td className='first-slot'>{slot}</td>
                                {daysOfWeek.map(day => {
                                    const formattedDay = dayNames[day.getDay()]; // Pon,Uto,Sri,Cet,Pet,Sub,Ned
                                    const date = new Date(day.getTime() - (day.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
                                    //const date = day.toISOString().split('T')[0]; // prebacanje datuma u ovaj format 'YYYY-MM-DD' da bi na klik moga da postavim tacno taj datum
                                    if (isSlotReserved(formattedDay, slot)) {
                                        return <td key={day + slot} className='reserved-slot'>R</td>;
                                    } else if (isSlotFree(formattedDay, slot)) {
                                        return (
                                            <td 
                                                key={day + slot} 
                                                className={`time-slot ${isHovered(formattedDay, slot) ? 'hovered' : ''} ${isSelected(formattedDay, slot) ? 'selected' : ''}`} 
                                                onMouseEnter={() => handleMouseEnter(formattedDay, slot)} 
                                                onMouseLeave={handleMouseLeave}
                                                onClick={() => handleSlotClick(formattedDay, slot, date)}
                                            >
                                                Zakaži
                                            </td>
                                        );
                                    } else {
                                        return <td key={day + slot}></td>;
                                    }
                                })}
                            </tr>
                            ))}

                    </tbody>
                </table>
                {/* odje bih dodao logiku da se prikaze termin koji je izabran ili da na zakazi dodam modal ocete li da potvrdite? */}
                {/* <p id='choosed-time'>{selectedSlots}</p> */}
                <button className='btn-tutor' onClick={handleZakaziClick}>ZAKAŽI</button>
            </div>
        </div>
        </>
    );
};

export default Scheduler;
