import { Link } from 'react-router-dom';
import useTutorReviews from '../hooks/useTutorReviews';

const TutorsList = ({ profesori }) => {
    const { getReviewText, getStars } = useTutorReviews();

    return ( 
        <div className="row justify-content-center">
    
            {profesori.map(profesor => (
                        <div  key={profesor.id_korisnika} className="tutor-box d-flex">
                            <div className='rec'>
                                {/* <img src={profesor.img} alt="Slika" /> */}
                                <img src={profesor.img} alt='' className="rounded-circle"/>
                                <div className='tx-l'>
                                    {getStars(profesor.prosjecnaOcjena)}
                                    <p className='upper'>{getReviewText(profesor.brojRecenzija)}</p>
                                    
                                    {profesor.verifikovan === 1 &&  <i className="bi bi-patch-check-fill"></i>}
                                </div>
                            </div>
                            <div className='info'>
                                <h3>{profesor.ime}</h3>
                                <div className='d-flex align-items-baseline'>
                                    <h4>Cijena:</h4>
                                    <p style={{marginLeft:'10px'}}>{profesor.cijena}€ - 60min</p>
                                </div>
                    
                                <h4>Predmeti:</h4>
                                <div className='d-flex w-flex'>
                                {profesor.predmeti.map((predmet, index) => (
                                    <span key={index} className='upper'>{predmet}</span>
                                ))}  
                                </div>
                                
                            </div>
                            <div className='bio'>
                                <h4>Biografija</h4>
                                {/* prvih 12 rijeci i ... */}
                                <p>{profesor.biografija.split(' ').slice(0, 12).join(' ') + '...'} 
                            </p>
                                <Link to={`/profesori/${encodeURIComponent(profesor.ime.toLowerCase().replace(/\s+/g, '-'))}-${profesor.id_korisnika}`} className='btn-tutor'>VIDI PROFESORA</Link>
                            </div>
                               
                        </div>
            ))}

        </div>
        
    );
}

export default TutorsList;