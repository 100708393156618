import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import ReviewModal from './components/ReviewModal';

import HomePage from './pages/HomePage';
import BecomeTutorPage from './pages/BecomeTutorPage';
import SubjectsListPage from './pages/SubjectsListPage';
import TutorsForSubjectPage from './pages/TutorsForSubjectPage';
import Tutor from './pages/Tutor';
import LoginPage from './pages/LoginPage';
import LoggedInPage from './pages/LoggedInPage';
import NotFoundPage from './pages/NotFoundPage';
import Success from './pages/Success';
import Chat from './pages/Chat';
import Podesvanja from './pages/Podesavanja';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import AdminDashboard from './pages/AdminDashboard';

import ProtectiveRoutes from './context/ProtectiveRoutes';
import ProtectiveRoutesAdmin from './context/ProtectiveRoutesAdmin';
import {AuthContext} from "./context/authContext.js";



function App() {
    const { currentUser } = useContext(AuthContext); 
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [professorsToReview, setProfessorsToReview] = useState([]);
    
    //za provjeru recenzija
    useEffect(() => {
        if (currentUser && currentUser.id_tipa === 3) {
            const checkIfNeedsReview = async () => {
                try {
                    const res = await axios.get('/api/reviews/check-review', {
                        params: { id_ucenika: currentUser.id_korisnika } 
                    });
    
                    if (res.data.needsReview) {
                        setProfessorsToReview(res.data.profesori); 
                        setShowReviewModal(true); 
                    }
                } catch (err) {
                    console.error('Error checking review status', err);
                }
            };
               
            checkIfNeedsReview();
        }
    }, [currentUser]);
    
    const handleReviewSubmit = () => {
        if (professorsToReview.length > 0) {
            setProfessorsToReview(prev => prev.slice(1)); // ako je vise recenzija makni prvu
        } else {
            setShowReviewModal(false); // Makni modal ako nema vise recenzija sto trb da se stave
        }
    };

    return (
        <Router>
                <ScrollToTop>
                    <div className="App">
                        <Header />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/postani-profesor" element={<BecomeTutorPage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/predmeti" element={<SubjectsListPage />} />
                            <Route path="/predmeti/:link" element={<TutorsForSubjectPage />} />
                            <Route path="/profesori/:ime-prezime-id" element={<Tutor />} />
                            <Route path="/success" element={<Success />} />
                            <Route path="/forget" element={<ForgotPassword />} />
                            <Route path="/reset-password/:token" element={<ResetPassword />} />
                            <Route path="/dashboard" element={<ProtectiveRoutes><LoggedInPage /></ProtectiveRoutes>} />   
                            <Route path="/poruke" element={<ProtectiveRoutes><Chat/></ProtectiveRoutes>} />
                            <Route path="/podesavanja" element={<ProtectiveRoutes><Podesvanja/></ProtectiveRoutes>} />
                            {/* trb napravit protectiveroutes za profesora, tj kad je ulogovan da mu neda da ode na postani-profesor*/}
                            <Route path="/admin-dashboard" element={<ProtectiveRoutesAdmin><AdminDashboard /></ProtectiveRoutesAdmin>} />

                           

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                        <Footer />
                    

                        {currentUser && showReviewModal && (
                            <ReviewModal 
                                isOpen={showReviewModal} 
                                onClose={() => setShowReviewModal(false)} 
                                idUcenika={currentUser.id_korisnika} 
                                professorsToReview={professorsToReview}
                                onSubmit={handleReviewSubmit}
                            />
                        )}
                            

                    </div>
                </ScrollToTop>
        </Router>
    );
}

export default App;
