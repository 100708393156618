import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/authContext'; 
//import Swal from 'sweetalert2'; 
import NavLoggedIn from '../components/NavLoggedIn';
import TutorScheduler from '../components/TutorScheduler';
import TutorSubjSettings from '../components/TutorSubjSettings';
import Modal from '../components/Modal';
import useModal from '../hooks/useModal';

const Podesavanja = () => {
  const [trenUser, setUser] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [biografija, setBiografija] = useState('');
  const [imageFile, setImageFile] = useState(null); // Polje za novu sliku
  const [isEditing, setIsEditing] = useState(false); // State za režim uređivanja
  const [updateMessage, setUpdateMessage] = useState('');
  const { modalVisible, modalContent, handleFormSuccess, handleFormFailure, setModalVisible} = useModal();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModalVisible(false);
    setIsEditing(false);
    navigate('/podesavanja'); 
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(`/api/auth/${currentUser.id_korisnika}`);
        setUser(res.data);
        setBiografija(res.data.biografija || '');
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUser?.id_korisnika) {
      getUser();
    }
  }, [currentUser.id_korisnika]);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]); // Postavi novu sliku
  };

  // Funkcija za upload slike
  const upload = async () => {
    if (!imageFile) return trenUser.img; // Ako slika nije izmenjena, zadrži staru sliku
    try {
      const formData = new FormData();
      formData.append('file', imageFile);
      const res = await axios.post('/api/upload', formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  // Funkcija za dovršavanje registracije (obavezna su oba polja)
  const handleCompleteRegistration = async (e) => {
    e.preventDefault();

    if (!biografija || !imageFile) {
      setUpdateMessage('Oba polja (biografija i slika) moraju biti popunjena!');
      return;
    }

    const imgUrl = await upload(); // Preuzmi novu sliku

    try {
      await axios.post('/api/auth/updateProfile', {
        userId: currentUser.id_korisnika,
        biografija,
        img: imgUrl,
      });

      handleFormSuccess('Vaš profil je uspješno ažuriran!');

    } catch (err) {
      console.log(err);
      handleFormFailure('Došlo je do greške prilikom ažuriranja profila.');
    }
  };

  // Funkcija za uređivanje podataka (slika nije obavezna)
  const handleEditProfile = async (e) => {
    e.preventDefault();

    if (!biografija) {
      setUpdateMessage('Biografija mora biti popunjena!');
      return;
    }

    const imgUrl = await upload(); // Preuzmi novu sliku ili zadrži staru

    try {
      await axios.post('/api/auth/updateProfile', {
        userId: currentUser.id_korisnika,
        biografija,
        img: imgUrl, // Slika (nova ili stara)
      });

      handleFormSuccess('Vaš profil je uspješno ažuriran!');

    } catch (err) {
      console.log(err);
      handleFormFailure('Došlo je do greške prilikom ažuriranja profila.');
    }
  };

  return (
    <>
    {modalVisible && <Modal {...modalContent} onClose={handleCloseModal}/>} 
    <section id="work-section" className="hero d-flex section-bg-w">
      <div className="container">
        <div className="row justify-content-between gy-5">
          <div className="menu-side col-lg-4 order-2 order-lg-1 d-flex flex-column align-items-center text-center text-lg-start">
            <NavLoggedIn />
          </div>
          <div className="settings-bottom-mg col-lg-8 order-1 order-lg-2 d-flex flex-column justify-content-center text-center">
            <div className="settings-container tutor-box">
              <div className="settings-header">
                <h2>Podešavanja korisnika</h2>
              </div>
              {trenUser && trenUser.isDone === '1' ? (
                <div className="contact">
                  {isEditing ? (
                    <form onSubmit={handleEditProfile} className="contact-email-form register-tutor" style={{ margin: '0 auto', boxShadow:'none' }}>
                      <div className="form-group">
                        <h4>Biografija:</h4>
                        <textarea
                          id="biografija"
                          value={biografija}
                          onChange={(e) => setBiografija(e.target.value)}
                          placeholder="Unesite vašu biografiju"
                          className="form-control"    style={{ height: '180px'}}
                        />
                      </div>

                      <div className="form-group">
                        <h4>Slika:</h4>
                        <input
                          id="slika"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="form-control"
                        />
                      </div>
                        {/* Prikaz stare slike ako nije odabrana nova */}
                        {!imageFile && trenUser.img && (
                          <div className="old-image-preview">
                            <h4>Trenutna slika:</h4>
                            <img src={`${trenUser.img}`} alt="Trenutna slika" className="profile-img2" />
                          </div>
                        )}

                      {updateMessage && <p className="form-message">{updateMessage}</p>}
                      <div className='d-flex justify-content-center w-flex'>
                      <button type="button" onClick={() => setIsEditing(false)} className="btn-prim mr-3" style={{marginRight:'10px'}}>Otkaži</button>
                      <button type="submit" className="submit-button">Sačuvaj promjene</button>
                      </div>
                    </form>
                  ) : (
                    <div className="user-info">
                      <div className='mb-3'><h4>Ime:</h4><p>{trenUser.ime}</p></div>
                      <div className='mb-3'><h4>Email:</h4><p>{trenUser.email}</p></div>
                      <div className='mb-3'><h4>Biografija:</h4><p>{trenUser.biografija || 'Nema biografije'}</p></div>
                      <div className='mb-3'>
                      <h4>Tip korisinika:</h4>
                      <p>
                        {trenUser.id_tipa === 1
                          ? 'Admin'
                          : trenUser.id_tipa === 2
                          ? 'Profesor'
                          : trenUser.id_tipa === 3
                          ? 'Učenik'
                          : 'Nepoznat tip'}
                      </p>
                    </div>

                      <div className='mb-3'>
                       
                     
                        <h4>Slika:</h4>
                        {trenUser.img ? (
                          <img src={`${trenUser.img}`} alt="Profil korisnika" className="profile-img2" />
                        ) : (
                          <p style={{ color: 'red' }}>Niste dodali fotografiju!</p>
                        )}

                      </div>

                      <div>
                      <button type="button" onClick={() => setIsEditing(true)} className="btn-tutor" style={{position:'static'}}>Izmjeni</button> 
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className='contact'>
                <form onSubmit={handleCompleteRegistration} className="contact-email-form register-tutor" style={{ margin: '0 auto', boxShadow:'none' }}>
                  <div className="form-group">
                    <h2 style={{color: 'var(--color-primary)'}}>Dovršite registraciju!</h2>
                    <h4>Biografija:</h4>
                    <textarea
                      id="biografija"
                      value={biografija}
                      onChange={(e) => setBiografija(e.target.value)}
                      placeholder="Unesite vašu biografiju"
                      className="form-control"
                      style={{ height: '180px'}}
                    />
                  </div>

                  <div className="form-group">
                    <h4>Slika:</h4>
                    <input
                      id="slika"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="form-control"
                    />
                  </div>

                  {updateMessage && <p className="form-message">{updateMessage}</p>}

                  <button type="submit" className="submit-button">Sačuvaj promjene</button>
                </form>
                </div>
              )}
            </div>

            {currentUser.id_tipa === 2 && <TutorScheduler profesorId={currentUser.id_korisnika}/>}

            {currentUser.id_tipa === 2 && <TutorSubjSettings profesorId={currentUser.id_korisnika}/>}

          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Podesavanja;
