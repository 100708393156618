import React, { useState } from 'react';
import axios from 'axios';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('/api/auth/forget-password', { email });
            setMessage('Provjerite vaš email za dalje uputstvo.');
        } catch (err) {
            console.error(err);
            setMessage('Došlo je do greške. Pokušajte ponovo.');
        }
    };

    return (
        <main>
        <section id="login-section" className="contact d-flex align-items-center section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Zaboravljena Lozinka</h2>
                    <p>Unesite vaš email da biste resetovali lozinku</p>
                </div>
                <div className="row">
                    <form onSubmit={handleSubmit} className="contact-email-form p-3 p-md-4 col-lg-6 register-tutor" style={{ margin: '0 auto' }}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                className="form-control" 
                                id="email" 
                                placeholder="Unesite vaš email" 
                                required 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        {message && <p className="form-message" style={{ color: 'red' }}>{message}</p>}

                        <div className="text-center">
                            <button type="submit">
                                Pošalji Uputstvo
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
          
        </main>
    );
};

export default ForgetPassword;
