import React, { useState } from 'react';
import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';


const StarRating = ({ rating, setRating }) => {
    const [hoverRating, setHoverRating] = useState(0); // State for hover effect
    const stars = [1, 2, 3, 4, 5];

    return (
        <div className="star-rating">
            {stars.map((star) => (
                <span
                    key={star}
                    className={`star ${star <= (hoverRating || rating) ? 'filled' : ''}`}
                    onClick={() => setRating(star)}
                    onMouseEnter={() => setHoverRating(star)} // Set hover rating
                    onMouseLeave={() => setHoverRating(0)} // Reset hover rating
                    style={{color: star <= (hoverRating || rating) ? 'gold' : 'white' }}
                >
                    <i className="bi bi-star-fill" style={{ color: star <= (hoverRating || rating) ? 'gold' : 'white' }} ></i>
                    
                    {/* <FontAwesomeIcon 
                        icon={faStar} 
                        style={{ color: star <= (hoverRating || rating) ? 'gold' : 'white' }} 
                    /> */}

                </span>
            ))}
        </div>
    );
};

const ReviewModal = ({ isOpen, onClose, idUcenika, professorsToReview, onSubmit }) => {
    const [rating, setRating] = useState(0); // Default to 0 (no rating selected)
    const [comment, setComment] = useState('');

    const handleSubmit = async () => {
        if (rating === 0) {
            alert('Molimo vas da ocijenite profesora prije nego što pošaljete recenziju.');
            return;
        }

        try {
            const currentProfessorId = professorsToReview[0].id_profesora;

            await axios.post('/api/reviews/send-review', {
                id_ucenika: idUcenika,
                id_profesora: currentProfessorId,
                ocjena: rating,
                tekst: comment
            });

            alert('Bravo! Recenzija je uspješno sačuvana.');

            onSubmit();

            // Reset the form for the next review
            setRating(0); // Reset to no stars selected
            setComment('');
        } catch (error) {
            console.error('Error saving review:', error);
        }
    };

    if (!isOpen || professorsToReview.length === 0) return null; // Hide modal if no professors to review

    const currentProfessor = professorsToReview[0];

    return (
        <div className="modal-overlay review-overlay">
            <div className="modal-content review">
                <h2>Čestitamo!</h2>
                <p>Održali ste čas kod profesora - <strong>{currentProfessor.ime}</strong>. Ocijenite profesora!</p>
                
                {/* Star Rating Component */}
                <div className='d-flex align-items-center justify-content-center w-flex mb-2 mt-2'>
                    <label className='mx-2'>Ocjena (1-5):</label>
                    <StarRating rating={rating} setRating={setRating} />
                </div>

                <div className='d-flex align-items-center justify-content-center w-flex mb-2 mt-2'>
                    <label htmlFor="komentar" className='mx-2 mb-2'>Komentar (opciono):</label>
                    <textarea 
                        id="komentar"
                        value={comment}
                        rows="2"
                        cols="30"
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Ostavite komentar (opciono)"
                    />
                </div>

                <button className='mt-3 submit-btn' onClick={handleSubmit}>Pošalji recenziju</button>

            </div>
        </div>
    );
};

export default ReviewModal;
