import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/' || window.location.hash !== '#contact') {
      window.scrollTo(0, 0); // skroluj do gore za druge rute
    }
  }, [pathname]);

  return children; // Renderuj str
};

export default ScrollToTop;
