import React, {useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../components/Modal';
import useModal from '../hooks/useModal';

const ResetPassword = () => {
    const { token } = useParams(); // Get token from URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { modalVisible, modalContent, handleFormSuccess, setModalVisible} = useModal();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setModalVisible(false);
        navigate('/login');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage('Lozinke se ne poklapaju!');
            return;
        }
            
        // Provera da li lozinka ima manje od 6 karaktera
        if (newPassword.length < 6) {
            setErrorMessage('Lozinka mora imati najmanje 6 karaktera.');
            return;
        }
        
        // Regularni izrazi za provjeru uslova
        const hasUpperCase = /[A-Z]/.test(newPassword); // Provjera za veliko slovo
        const hasNumber = /\d/.test(newPassword);       // Provjera za broj
        const hasSpecialChar = /[!@#%&*?+=._-]/.test(newPassword); // Provjera za specijalni karakter
        
        // Ako bilo koji uslov nije zadovoljen, prijavi grešku
        if (!hasUpperCase || !hasNumber || !hasSpecialChar) {
            setErrorMessage('Lozinka mora sadržati barem jedno veliko slovo, broj i specijalni karakter (!, @, #, itd...)');
            return;
        }
        try {
            await axios.post('/api/auth/reset-password', { token, newPassword });
            //alert('Vaša lozinka je uspješno promjenjena!');
            handleFormSuccess("Vaša lozinka je uspješno promjenjena!");
        } catch (err) {
            console.error(err);
            setErrorMessage('Došlo je do greške prilikom promjene lozinke.');
        }
    };

    return (
        <>
        {modalVisible && <Modal {...modalContent} onClose={handleCloseModal}/>} 
        <main>
            <section id="login-section" className="contact d-flex align-items-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>Resetovanje Lozinke</h2>
                        <p>Unesite novu lozinku za svoj nalog</p>
                    </div>
                    <div className="row">
                        <form onSubmit={handleSubmit} className="contact-email-form p-3 p-md-4 col-lg-6 register-tutor" style={{ margin: '0 auto' }}>
                            <div className="form-group">
                                <label htmlFor="new-password">Nova Lozinka</label>
                                <input 
                                    type="password" 
                                    name="new-password" 
                                    className="form-control" 
                                    id="new-password" 
                                    placeholder="Nova Lozinka" 
                                    required 
                                    value={newPassword} 
                                    onChange={(e) => setNewPassword(e.target.value.trim())}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="confirm-password">Potvrdite Lozinku</label>
                                <input 
                                    type="password" 
                                    name="confirm-password" 
                                    className="form-control" 
                                    id="confirm-password" 
                                    placeholder="Potvrdite Lozinku" 
                                    required 
                                    value={confirmPassword} 
                                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                                />
                            </div>

                            {errorMessage && <p className="form-message" style={{ color: 'red' }}>{errorMessage}</p>}

                            <div className="text-center">
                                <button type="submit">
                                    Resetuj Lozinku
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
        </>
    );
};

export default ResetPassword;
