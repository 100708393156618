const NotFoundPage = () => (
    <section id="hero" className="hero d-flex align-items-center section-bg-w">
    <div className="container">
      <div className="row justify-content-between gy-5">
        <div className="col-lg-5 order-1 order-lg-2 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
          <h2 data-aos="fade-up" className="aos-init aos-animate">Stranica nije pronadjena!</h2>
        </div>
        <div className="col-lg-6 order-2 order-lg-1 text-center text-lg-start">
          <img src="/assets/images/learning.jpg" className="img-fluid aos-init aos-animate" alt="" data-aos="zoom-out" data-aos-delay="300"/>
        </div>
      </div>
    </div>
  </section>
);

export default NotFoundPage;