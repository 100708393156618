import React, { useContext, useEffect, useState, useRef } from 'react';
import NavLoggedIn from '../components/NavLoggedIn';
import { AuthContext } from '../context/authContext';
import axios from "axios";
import socket from '../components/Socket';  // Uvezi socket iz socket.js

function Chat() {
  const [users, setUsers] = useState([]);  // Učitani korisnici s kojima je bilo konverzacija
  const [currentConversation, setCurrentConversation] = useState(null);  // Trenutna aktivna konverzacija
  const [messages, setMessages] = useState([]);  // Poruke trenutne konverzacije
  const [newMessage, setNewMessage] = useState('');  // Nova poruka koju korisnik unosi
  const { currentUser } = useContext(AuthContext);  // Trenutni korisnik iz konteksta
  const [selectedUser, setSelectUsers] = useState(null);  // Odabrani korisnik s kojim je konverzacija
  
  const [notifications, setNotifications] = useState({});  // Notifikacije (nepročitane poruke)
  const messagesEndRef = useRef(null);  // Ref za automatsko skrolovanje na kraj poruka
  const chatBoxRef = useRef(null);  // Ref za skrolovanje unutar poruka

  // Učitavanje korisnika s kojima je bilo konverzacija, uključujući broj nepročitanih poruka
  useEffect(() => {
    axios.get(`/api/chat/history/${currentUser.id_korisnika}`)
        .then(response => {
          setUsers(response.data);  // Postavimo učitane korisnike
          const initialNotifications = {};
          response.data.forEach(user => {
            initialNotifications[user.id_korisnika] = user.unreadCount;  // Povežemo broj nepročitanih poruka sa svakim korisnikom
          });
          setNotifications(initialNotifications);
        })
        .catch(error => console.error('Error fetching users:', error));

    // Registrujemo korisnika na socket.io
    socket.emit('register', currentUser.id_korisnika);

    // Slušamo dolazne poruke
    socket.on('receiveMessage', (message) => {
      if (message.sender_id === currentConversation?.id_korisnika || message.receiver_id === currentConversation?.id_korisnika) {
        // Ako je poruka iz trenutne konverzacije, dodajemo je u prikaz poruka
        setMessages(prevMessages => [...prevMessages, message]);
      } else {
        // Ako nije iz trenutne konverzacije, ažuriramo broj nepročitanih poruka
        updateUserUnreadCount(message.sender_id);
      }
    });

    return () => {
        socket.off('receiveMessage');  // Čistimo event listener prilikom izlaska iz komponente
    };
  }, [currentUser.id_korisnika, currentConversation?.id_korisnika]);

  // Funkcija za ažuriranje broja nepročitanih poruka
  const updateUserUnreadCount = (senderId) => {
    setNotifications(prevNotifications => ({
      ...prevNotifications,
      [senderId]: (prevNotifications[senderId] || 0) + 1
    }));

    setUsers(prevUsers => 
      prevUsers.map(user => 
        user.id_korisnika === senderId
          ? { ...user, unreadCount: (user.unreadCount || 0) + 1 }
          : user
      )
    );
  };

  // Klik na korisnika - pokretanje konverzacije
  const handleUserClick = (user) => {
    setCurrentConversation(user);
    setSelectUsers(user);

    // Emitujemo događaj markAsSeen za ažuriranje statusa poruka u bazi
    socket.emit('markAsSeen', { receiverId: currentUser.id_korisnika, senderId: user.id_korisnika });
    socket.emit('markAsRead', { receiverId: currentUser.id_korisnika, tip_notifikacije: 1 });

    // Učitavamo poruke za odabranu konverzaciju
    axios.get(`/api/chat/messages/conv/${currentUser.id_korisnika}/${user.id_korisnika}`)
      .then(res => setMessages(res.data));

    // Resetujemo broj nepročitanih poruka za tog korisnika
    setUsers(prevUsers => 
      prevUsers.map(u => 
        u.id_korisnika === user.id_korisnika
          ? { ...u, unreadCount: 0 }
          : u
      )
    );
  };

  // Slanje nove poruke
  const handleSendMessage = () => {
    if (newMessage.trim() === '' || !currentConversation) return;

    const message = {
        sender_id: currentUser.id_korisnika,
        receiver_id: selectedUser.id_korisnika,
        message_text: newMessage,
        created_at: new Date().toISOString() 
    };
    console.log("Prije pozivanja soketa");
    // Emitujemo novu poruku preko socket.io
    socket.emit('sendMessage', message);
    console.log("Posle");

    // Dodajemo poruku u prikaz
    setMessages(prevMessages => [...prevMessages, message]);
    setNewMessage('');  // Resetujemo unos
  };

  // Formatiranje datuma i vremena za prikaz
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const optionsDate = { day: '2-digit', month: 'short' };  
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate); 

    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);

    return `${formattedDate}, ${formattedTime}`;
  };

  // Skrolovanje na kraj poruka prilikom dodavanja novih poruka
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <section id="work-section" className="hero d-flex section-bg-w">
      <div className="container">
        <div className="row justify-content-between gy-5">
          <div className="menu-side col-lg-4 order-2 order-lg-1 d-flex flex-column align-items-center text-center text-lg-start">
            <NavLoggedIn/>
          </div>
          <div className="chat-bottom-mg col-lg-8 order-1 order-lg-2 d-flex flex-column justify-content-center text-center">
            <div className="chat-container">
              <div className="chat-sidebar">
                <h2>Korisnici</h2>
                <ul>
                  {users.map(user => (
                    <li
                      key={user.id_korisnika}
                      className={currentConversation?.id_korisnika === user.id_korisnika ? 'active' : ''}
                      onClick={() => handleUserClick(user)}
                    >
                      {user.ime}
                      {/* Prikaz broja nepročitanih poruka za svakog korisnika */}
                      {notifications[user.id_korisnika] > 0 && (
                        <span className="notification-badge">{notifications[user.id_korisnika]}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="chat-main">
                {currentConversation ? (
                  <>
                    <div className="chat-messages" ref={chatBoxRef}>
                      {messages.map((msg, index) => (
                        <div key={index} className={msg.sender_id === currentUser.id_korisnika ? 'message sent' : 'message received'}>
                          <p>{msg.message_text}</p>
                          <small>{formatDate(msg.created_at)}</small>
                          {msg.sender_id === currentUser.id_korisnika && index === messages.length - 1 && (
                            <div className="seen-status">
                              {msg.seen === 'D' ? 'Seen' : 'Delivered'}
                            </div>
                          )}
                        </div>
                      ))}
                      <div ref={messagesEndRef} />
                    </div>
                    <div className="chat-input">
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Otkucaj poruku"
                      />
                      <button onClick={handleSendMessage}>Pošalji</button>
                    </div>
                  </>
                ) : (
                  <h2 className="no-conversation">Izaberite korisnika</h2>
                )}
              </div>
            </div>   
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;
